import classNames from 'classnames';

import { useToggleState } from '@pumpkincare/portal-shared';
import { Body1, Button, Popover } from '@pumpkincare/portal-ui';

import styles from './patient-sort.css';

import arrowDown from 'images/icons/arrow-down.svg'; // eslint-disable-line import/no-unresolved

export default function PatientSort({
  options,
  selected,
  onChangeHandler = () => {},
}) {
  const selectedOption = options.find(opt => opt[selected]);
  const [isSortByOpen, toggleSortBy] = useToggleState(false);

  function sortByChanged(value) {
    toggleSortBy();
    onChangeHandler(value);
  }

  function getSortByButton(dir) {
    return (
      <Button className={styles.headerButton} onClick={toggleSortBy}>
        <Body1 className={styles.headerLabel}>Sort By:</Body1>
        {selectedOption ? (
          <Body1 className={styles.selectedOption}>{selectedOption[selected]}</Body1>
        ) : null}
        <img
          className={classNames(styles.icon, { [styles.reverse]: !!dir })}
          src={arrowDown}
          alt={`arrow ${dir || 'down'} icon`}
        />
      </Button>
    );
  }

  return (
    <div className={styles.patientSortContainer}>
      <div>
        {getSortByButton()}
        {isSortByOpen ? (
          <div>
            <Popover className={styles.popover} onOutsideClick={toggleSortBy}>
              {getSortByButton('up')}
              {options?.map(option => {
                const key = Object.keys(option)[0];
                return (
                  <Button
                    className={styles.optionButton}
                    key={key}
                    onClick={() => {
                      sortByChanged(key);
                    }}
                  >
                    <Body1 className={styles.optionlabel}>{option[key]}</Body1>
                  </Button>
                );
              })}
            </Popover>
          </div>
        ) : null}
      </div>
    </div>
  );
}
