import { apiPost } from '../../app';
import { setVisitPatient } from '../state';

export default function createVisitPatient(vetId, patientData) {
  return (dispatch, getState) => {
    return dispatch(
      apiPost({
        url: `/vets/${vetId}/visits`,
        options: {
          body: {
            client_first_name: patientData.firstName,
            client_last_name: patientData.lastName,
            client_email: patientData.email,
            pet_name: patientData.petName,
          },
          handleActions: setVisitPatient,
        },
      })
    );
  };
}
