import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  ADDRESS,
  PATH_EXT_CALIFORNIA_NOTICE,
  PATH_EXT_MAINE_NOTICE,
} from '../../../app';

import styles from './pawsights-footer.css';

import logo from 'images/logos/pumpkin.svg';

function PawsightsFooter() {
  const { punks905IaicChanges } = useFlags();

  return (
    <footer className={styles.root} id='footer'>
      <div className={styles.content}>
        <img src={logo} className={styles.logo} alt='' />

        <a href={PATH_EXT_CALIFORNIA_NOTICE} target='_blank' rel='noreferrer'>
          Notice to California Residents
        </a>
        <a href={PATH_EXT_MAINE_NOTICE} target='_blank' rel='noreferrer'>
          Notice to Maine Residents
        </a>

        <p>
          Pawsights reports and Pawsights services are created and provided by
          Pumpkin.
        </p>
        <p>
          Pumpkin Pet Insurance policies do not cover pre-existing conditions.
          Waiting periods, annual deductible, co-insurance, benefit limits and
          exclusions may apply. For full terms, visit pumpkin.care/insurancepolicy.
          Products, discounts, and rates may vary and are subject to change.
        </p>

        {punks905IaicChanges ? (
          <p>
            Insurance products are underwritten by either Independence American
            Insurance Company, a Delaware insurance company (NAIC #2658; Scottsdale,
            AZ), or United States Fire Insurance Company (NAIC #21113; Morristown,
            NJ); and are administered and produced by Pumpkin Insurance Services Inc.
            (“Pumpkin”) (NPN #19084749; domiciled in New York with offices at{' '}
            {ADDRESS}; CA License #6001617). Please refer to your policy forms to
            determine your policy’s underwriter. Pumpkin is a licensed insurance
            agency, not an insurer, and receives compensation based on the premiums
            for the insurance policies it sells. For more details, visit
            pumpkin.care/underwriting-information.
          </p>
        ) : (
          <p>
            Pumpkin Insurance Services Inc. (“Pumpkin”) (NPN #19084749) is a licensed
            agency, not an insurer. Insurance is underwritten by United States Fire
            Insurance Company (NAIC #21113. Morristown, NJ), a Crum & Forster Company
            and produced by Pumpkin. Pumpkin receives compensation based on the
            premiums for the insurance policies it sells.
          </p>
        )}
        <p>
          Pumpkin Preventive Essentials is not an insurance policy. It is offered as
          an optional add-on non-insurance benefit. Pumpkin is responsible for the
          product and administration. Pumpkin Preventive Essentials is not available
          in all states. For full terms, visit pumpkin.care/customeragreement.
        </p>
        <p>
          Copyright © {new Date().getFullYear()} Pumpkin Insurance Services Inc. All
          rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default PawsightsFooter;
