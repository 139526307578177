import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { DOG, ERROR, formatSearchFromQuery } from '@pumpkincare/portal-shared';
import { Body2, Processing } from '@pumpkincare/portal-ui';

import { AppMeta, getRouterLocation, PATH_PATIENTS_BASE } from '../../../app';
import PET_IMAGES from '../../constants/pet-images';
import { getPatient, getPatientsPets, getPatientStatus } from '../../selectors';
import getPatientActivePet from '../../selectors/reselect/get-patient-active-pet';
import downloadPolicy from '../../thunks/download-policy';
import PatientNav from '../patient-nav';
import PatientPlan from '../patient-plan';
import PatientPolicy from '../patient-policy';

import styles from './patient-view.css';

import cakeIcon from 'images/icons/cake.svg';
import genderIcon from 'images/icons/gender.svg';
import pawIcon from 'images/icons/paw.svg';

export default function PatientView() {
  const dispatch = useDispatch();
  const { pathname, query } = useSelector(getRouterLocation);
  const user = useSelector(getPatient);
  const userStatus = useSelector(getPatientStatus);
  const fullPets = useSelector(getPatientsPets) || {};

  useEffect(() => {
    if (userStatus === ERROR) {
      dispatch(push({ pathname: PATH_PATIENTS_BASE }));
    }
  }, [dispatch, userStatus]);

  const { pets, id } = user || { pets: [] };

  const selectedPet = query.pet || null;
  const activePet =
    useSelector(state =>
      getPatientActivePet(
        state,
        pets.filter(pet => pet.id === selectedPet || pet.name === selectedPet)[0] ||
          pets[0]
      )
    ) || {};

  function handleClickPet(newPet) {
    const newQuery = { ...query };
    newQuery.pet = newPet;
    dispatch(
      push({
        pathname,
        search: formatSearchFromQuery(newQuery),
      })
    );
  }

  return (
    <>
      <AppMeta
        titleData={{ firstName: user?.first_name, lastName: user?.last_name }}
      />
      <div className={styles.container}>
        <PatientNav
          accordionTitle='Pet Details'
          goBackText='Back to Search'
          goBackLink='/patients'
          fullPets={fullPets}
          onPetClick={handleClickPet}
          selectedPet={selectedPet}
          user={user}
        />

        <main className={styles.content}>
          <h3 className={styles.desktop}>Pet Details</h3>

          {Object.keys(activePet).length ? (
            <div className={styles.activePetInfo}>
              <img
                alt='pet cartoon'
                role='presentation'
                className={styles.activePetImage}
                src={
                  PET_IMAGES[activePet.species]?.[activePet.gender] ||
                  PET_IMAGES.default
                }
              />
              <div className={styles.activePetText}>
                <h4>{activePet.name}</h4>
                {activePet.species ? (
                  <>
                    <Body2 className={styles.activePetItem}>
                      <img src={pawIcon} alt='paw' role='presentation' />
                      {activePet.breed_name}
                    </Body2>
                    <Body2 className={styles.activePetItem}>
                      <img src={cakeIcon} alt='cake' role='presentation' />
                      {activePet.formattedAge}
                    </Body2>
                    <Body2 className={styles.activePetItem}>
                      <img src={genderIcon} alt='gender' role='presentation' />
                      {activePet.formattedGender}
                    </Body2>
                  </>
                ) : (
                  <Body2 className={styles.activePetItem}>No Pumpkin Policy</Body2>
                )}
              </div>
            </div>
          ) : (
            <Processing />
          )}
          {activePet.id ? (
            <PatientPolicy
              activePolicy={activePet.policy}
              downloadPolicy={() => dispatch(downloadPolicy(activePet.policy.id))}
              classes={{
                module: styles.module,
                moduleHeader: styles.moduleHeader,
                moduleIcon: styles.moduleIcon,
                moduleLineItem: styles.moduleLineItem,
              }}
            />
          ) : null}

          {activePet.plan ? (
            <PatientPlan
              activePlan={activePet.plan}
              isDog={activePet.species === DOG}
              classes={{
                module: styles.module,
                moduleHeader: styles.moduleHeader,
                moduleIcon: styles.moduleIcon,
                moduleLineItem: styles.moduleLineItem,
              }}
            />
          ) : null}
        </main>
      </div>
    </>
  );
}
