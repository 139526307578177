import { A } from '@pumpkincare/portal-ui';

import styles from './business-insider.css';

import sealIcon from 'images/icons/seal.svg';
import businessInsiderLogo from 'images/logos/business-insider.png';

export default function BusinessInsider() {
  return (
    <div className={styles.award}>
      <div className={styles.container}>
        <img src={sealIcon} className={styles.seal} alt='' />

        <img
          src={businessInsiderLogo}
          className={styles.logo}
          alt='Business Insider Logo'
        />

        <h3 className={styles.h3}>
          Pumpkin awarded “Best Pet Insurance for Comprehensive Coverage” in 2021 by
          BusinessInsider.com
          <A
            href='https://www.pumpkin.care/references'
            target='_blank'
            className={styles.link}
          >
            15
          </A>
        </h3>
      </div>
    </div>
  );
}
