import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';

import { useTargetState, validateEmail } from '@pumpkincare/portal-shared';
import { A, Body1, ButtonPrimary, TextField } from '@pumpkincare/portal-ui';
import { useVetLogIn } from '@pumpkincare/portal-user';

import { CONTACT_EMAIL, CONTACT_EMAIL_LINK } from '../../constants';
import {
  PATH_ACCOUNT_BASE,
  PATH_EXT_DVM_ACCESS,
  PATH_REQUEST_RESET,
} from '../../routing';

import styles from './login-page.css';

import petsAtVet from 'images/illustrations/multipet/vet.png';

const ERRORS = {
  email: 'Please enter a valid email to continue',
  password: 'Please enter a value to continue',
  UserNotFoundException:
    "Ruh-roh! We couldn't find an account that matches this email or password. Please try again",
  NotAuthorizedException:
    "Ruh-roh! We couldn't find an account that matches this email or password. Please try again",
};

function LoginPage() {
  const dispatch = useDispatch();

  const [email, setEmail, isEmailValid] = useTargetState('', validateEmail);
  const [password, setPassword, isPasswordValid] = useTargetState('');
  const [areErrorsVisible, setErrorsVisible] = useState(false);
  const [isLoading, setIsFetching] = useState(false);
  const [error, setError] = useState('');
  const logIn = useVetLogIn();

  function handleLogInSubmit(e) {
    e.preventDefault();

    if (!isEmailValid || !isPasswordValid) {
      return setErrorsVisible(true);
    }

    setIsFetching(true);
    return logIn(email, password)
      .then(() => {
        dispatch(push(PATH_ACCOUNT_BASE));
      })
      .catch(err => {
        setError(err);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }

  return (
    <div className={styles.container}>
      <img
        src={petsAtVet}
        alt='pets at the vet'
        role='presentation'
        className={styles.image}
      />
      <h4 className={styles.title}>Log In to PawPortal</h4>

      <form onSubmit={handleLogInSubmit}>
        <TextField
          onChange={setEmail}
          value={email}
          id='login-email'
          label='Email'
          error={{ errorMessage: areErrorsVisible && !isEmailValid && ERRORS.email }}
        />
        <TextField
          onChange={setPassword}
          value={password}
          id='login-password'
          label='Password'
          type='password'
          error={{
            errorMessage: areErrorsVisible && !isPasswordValid && ERRORS.password,
          }}
        />
        <ButtonPrimary isLoading={isLoading} className={styles.button} type='submit'>
          Log In
        </ButtonPrimary>
      </form>

      {error ? (
        <Body1 className={styles.error}>
          {ERRORS[error.code] || (
            <>
              Something went wrong. Please try logging in again, or reach out to{' '}
              <A href={CONTACT_EMAIL_LINK}>{CONTACT_EMAIL}</A> if the issue persists
            </>
          )}
        </Body1>
      ) : null}

      <Link to={PATH_REQUEST_RESET} className={styles.link}>
        Reset My Password
      </Link>
      <Body1 className={styles.requestAccess}>
        Need to add a new user for your clinic?{' '}
        <A href={PATH_EXT_DVM_ACCESS} target='_blank'>
          Request New Account
        </A>
      </Body1>
    </div>
  );
}

export default LoginPage;
