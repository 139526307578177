import Amplify from '@aws-amplify/core';

import { AWS_AMPLIFY_SETTINGS, BASE_API } from './env';

export const USER_SRP_AUTH = 'USER_SRP_AUTH';
export const CUSTOM_AUTH = 'CUSTOM_AUTH';

const AuthenticationFlowTypes = Object.freeze({
  USER_SRP_AUTH,
  CUSTOM_AUTH,
});

export function configureAmplify(authenticationFlowType) {
  if (!AuthenticationFlowTypes[authenticationFlowType]) {
    throw Error('Invalid authentication flow type');
  }

  Amplify.configure({
    Auth: {
      ...AWS_AMPLIFY_SETTINGS,
      region: 'us-east-1',
      identityPoolRegion: 'us-east-1',
      mandatorySignIn: true,
      authenticationFlowType,
    },
    API: {
      endpoints: [
        {
          name: 'vetApi',
          endpoint: BASE_API,
        },
      ],
    },
  });
}
