// Based on: https://medium.com/swlh/build-a-date-picker-in-15mins-using-javascript-react-from-scratch-f6932c77db09

import { createRef, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';

import {
  dateIsAfter,
  dateIsBefore,
  dateIsBetween,
  formatDateFromTextInput,
  formatISODate,
  formatMMDDYYYYtoYYYYMMDD,
  USER_AGENTS,
  useTargetState,
  validateDateMMDDYYY,
} from '@pumpkincare/portal-shared';

import TextField from '../text-field';
import {
  getDateFromDateString,
  getDateStringFromTimestamp,
  getMonthDetails,
  getMonthStr,
  handleDateFormat,
  returnEvent,
} from './date-input-utils';

import styles from './date-input.css';

import calendarIcon from 'images/icons/calendar.svg';

const date = new Date();
const oneDay = 60 * 60 * 24 * 1000;
const todayTimestamp =
  Date.now() - (Date.now() % oneDay) + new Date().getTimezoneOffset() * 1000 * 60;

export default function DateInput({
  onChange,
  inputId = 'date-input',
  errorMessage = '',
  initialValue = null,
  isRequired = true,
  min = '01/01/1900',
  max = '12/31/2099',
  classes = {},
}) {
  const initialDay = useMemo(() => {
    if (initialValue) {
      const dateObj = getDateFromDateString(initialValue);
      if (dateObj !== null) {
        return new Date(
          dateObj.yearValue,
          dateObj.monthValue - 1,
          dateObj.dayValue
        ).getTime();
      }
    }

    return '';
  }, [initialValue]);

  const el = useRef(null);
  const ref = createRef();
  const [selectedDay, setSelectedDay] = useState(initialDay);
  const [year, setYear] = useState(date.getFullYear());
  const [month, setMonth] = useState(date.getMonth());
  const [monthDetails, setMonthDetails] = useState(
    getMonthDetails(date.getFullYear(), date.getMonth())
  );
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [typedDate, setTypedDate, isValidTypedDate] = useTargetState(
    getDateStringFromTimestamp(selectedDay),
    validateDateMMDDYYY
  );
  const [error, setError] = useState('');

  function isCurrentDay(day) {
    return day.timestamp === todayTimestamp && day.month === 0;
  }

  function isSelectedDay(day) {
    return day.timestamp === selectedDay && day.month === 0;
  }

  function isDisabledDay(day) {
    if (min && dateIsBefore(getDateStringFromTimestamp(day.timestamp), min)) {
      return true;
    }
    if (max && dateIsAfter(getDateStringFromTimestamp(day.timestamp), max)) {
      return true;
    }
    return day.month !== 0;
  }

  function setYearValue(offset) {
    const newYear = year + offset;
    setYear(newYear);
    setMonthDetails(getMonthDetails(newYear, month));
  }

  function setMonthValue(offset) {
    let newYear = year;
    let newMonth = month + offset;
    if (newMonth === -1) {
      newMonth = 11;
      newYear -= 1;
    } else if (month === 12) {
      newMonth = 0;
      newYear += 1;
    }
    setYear(newYear);
    setMonth(newMonth);
    setMonthDetails(getMonthDetails(newYear, newMonth));
  }

  function setDateValue(timestamp) {
    setSelectedDay(timestamp);
    if (timestamp) {
      setError('');
      setTypedDate(returnEvent(getDateStringFromTimestamp(timestamp)));
    }
  }

  function onDateClick(day) {
    setShowDatePicker(false);
    setDateValue(day.timestamp);
    onChange(returnEvent(getDateStringFromTimestamp(day.timestamp)));

    const dateString = getDateStringFromTimestamp(day.timestamp);
    ref.current.value = dateString;
  }

  function updateDateFromInput(e) {
    const inputValue = e.target.value;
    const dateValue = formatISODate(inputValue);
    const dateObj = getDateFromDateString(dateValue);

    if (dateObj !== null) {
      const newSelectedDay = new Date(
        dateObj.yearValue,
        dateObj.monthValue - 1,
        dateObj.dayValue
      ).getTime();
      setDateValue(newSelectedDay);
      setYear(dateObj.yearValue);
      setMonth(dateObj.monthValue - 1);
      setMonthDetails(getMonthDetails(dateObj.yearValue, dateObj.monthValue - 1));
      onChange(returnEvent(dateValue));
    } else {
      setDateValue('');
      setYear(date.getFullYear());
      setMonth(date.getMonth());
      setMonthDetails(getMonthDetails(date.getFullYear(), date.getMonth()));
      setTypedDate(returnEvent(''));
      onChange(returnEvent(''));
    }
  }

  function renderCalendar() {
    return (
      <div className={styles.calendarContainer}>
        <div className={styles.calendarHead}>
          {['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'].map(d => (
            <div key={d} className={styles.calendarDayName}>
              {d}
            </div>
          ))}
        </div>
        <div className={styles.calendarBody}>
          {monthDetails.map(day => {
            return (
              <div
                className={classNames(styles.dayContainer, {
                  [styles.disabled]: isDisabledDay(day),
                  [styles.highlight]: isCurrentDay(day),
                  [styles.highlightActive]: isSelectedDay(day),
                })}
                key={`${day.timestamp}-${day.month}`}
              >
                <div className={styles.day}>
                  <button onClick={() => onDateClick(day)} type='button'>
                    {day.date}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  useEffect(() => {
    function addBackDrop(e) {
      if (showDatePicker && el?.current && !el.current.contains(e.target)) {
        setShowDatePicker(false);
      }
    }

    window.addEventListener('click', addBackDrop);

    // returned function will be called on component unmount
    return () => {
      window.removeEventListener('click', addBackDrop);
    };
  }, [showDatePicker]);

  return (
    <div className={classNames(styles.container, classes.container)}>
      {!USER_AGENTS.desktop ? (
        <label htmlFor='date-picker' className={styles.mobileHandler} />
      ) : null}
      <div className={styles.inputContainer}>
        <TextField
          label='MM/DD/YYYY'
          id={inputId}
          inputRef={ref}
          value={typedDate}
          errorMessage={error || errorMessage}
          onChange={setTypedDate}
          onKeyUp={handleDateFormat}
          onBlur={e => {
            setTypedDate(returnEvent(formatDateFromTextInput(typedDate)));
            if (isValidTypedDate && dateIsBetween(typedDate, min, max, '[]')) {
              return updateDateFromInput(e);
            }

            if (!typedDate && isRequired) {
              setError('The value is required');
            } else if (!isValidTypedDate) {
              setError('Invalid date format');
            } else {
              setError(`Out of the allowed range (${min} - ${max})`);
            }
            return updateDateFromInput(returnEvent(''));
          }}
        />
        <button
          type='button'
          onClick={() => setShowDatePicker(true)}
          className={styles.buttonIcon}
        >
          <img
            alt=''
            role='presentation'
            id='calendar-icon'
            src={calendarIcon}
            className={styles.inputIcon}
          />
        </button>
        <input
          type='date'
          className={styles.input}
          id='date-picker'
          onChange={updateDateFromInput}
          min={min ? formatMMDDYYYYtoYYYYMMDD(min) : ''}
          max={max ? formatMMDDYYYYtoYYYYMMDD(max) : ''}
        />
      </div>
      {showDatePicker ? (
        <div ref={el} className={styles.wrapper}>
          <div className={styles.head}>
            <div className={styles.button}>
              <button
                className={styles.inner}
                onClick={() => setYearValue(-1)}
                type='button'
              >
                <span className={styles.leftArrows} />
              </button>
            </div>
            <div className={styles.button}>
              <button
                className={styles.inner}
                onClick={() => setMonthValue(-1)}
                type='button'
              >
                <span className={styles.leftArrow} />
              </button>
            </div>
            <div className={styles.headerContainer}>
              <div className={styles.year}>{year}</div>
              <div className={styles.month}>{getMonthStr(month)}</div>
            </div>
            <div className={styles.button}>
              <button
                className={styles.inner}
                onClick={() => setMonthValue(1)}
                type='button'
              >
                <span className={styles.rightArrow} />
              </button>
            </div>
            <div className={styles.button}>
              <button
                className={styles.inner}
                onClick={() => setYearValue(1)}
                type='button'
              >
                <span className={styles.rightArrows} />
              </button>
            </div>
          </div>
          <div className={styles.body}>{renderCalendar()}</div>
        </div>
      ) : null}
    </div>
  );
}
