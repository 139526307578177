import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { push } from 'connected-react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDebounce } from 'use-debounce';

import { analyticsIdentify, analyticsTrack } from '@pumpkincare/portal-analytics';
import {
  getQuotePets,
  useMutatePatchQuotePet,
  useMutatePatchQuoteVet,
  useMutateRegisterQuote,
  useQuote,
  useQuoteEmail,
} from '@pumpkincare/portal-quotes';
import {
  filterUTMParams,
  PET_AGE_OPTIONS,
  useBreeds,
  useTargetState,
  useZipcodes,
  validateEmail,
} from '@pumpkincare/portal-shared';
import {
  ButtonPrimary,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@pumpkincare/portal-ui';
import { useVet } from '@pumpkincare/portal-vets';

import {
  PATH_EXT_PRIVACY,
  PATH_EXT_TERMS,
  PATH_PAWSIGHTS_LANDING,
  PATH_PAWSIGHTS_REPORT,
} from '../../../app';
import { useMutateSendPawsightsEmail } from '../../utils/pawsights-mutations';
import PawsightsContact from '../pawsights-contact';
import PawsightsFooter from '../pawsights-footer';
import PawsightsNavBar from '../pawsights-nav-bar';
import Stepper from '../stepper';

import styles from './pawsights-form.css';

import helloStep from 'images/icons/hello-circle.svg';
import pawStep from 'images/icons/paw-circle.svg';
import reportStep from 'images/icons/report-circle-inactive.svg';
import catBoy from 'images/illustrations/cat/boy-face.svg';
import catGirl from 'images/illustrations/cat/girl-face.svg';
import dogBoy from 'images/illustrations/dog/boy-face.svg';
import dogGirl from 'images/illustrations/dog/girl-face.svg';

const helperText = {
  dog: 'If you have a mixed-breed dog and know their primary breed, you can select that breed mix option. (eg. French Bulldog Mix or Husky Mix). If you are unsure of your dog’s breed mix, select the size-specific mix that best fits (e.g. Small/Medium/Large Mix).',
  cat: 'If you have a mixed-breed cat, you can select their mixed breed based on fur length (Domestic Shorthair, Mediumhair, or Longhair), or if you’re not sure which of those fits best, you can select ‘Mixed Cat’.',
};

const icons = {
  cat: { boy: catBoy, girl: catGirl },
  dog: { boy: dogBoy, girl: dogGirl },
};

function PawsightsForm() {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const utms = filterUTMParams(search);
  const { dawgs1906PawsightsEmailCapture } = useFlags();

  const vetId = sessionStorage.getItem('@pumpkincare/vetId');
  const quoteId = sessionStorage.getItem('@pumpkincare/quoteId');
  const sessionEmail = sessionStorage.getItem('@pumpkincare/email');

  useEffect(() => {
    if (!quoteId) {
      const searchString = vetId ? `?vet_id=${vetId}&${utms}` : `?${utms}`;
      dispatch(push({ pathname: PATH_PAWSIGHTS_LANDING, search: searchString }));
    } else {
      analyticsTrack({
        category: 'Pawsights',
        event: 'Step 2 impression',
      });
    }
  }, [dispatch, quoteId, vetId, utms]);

  const [gender, setGender] = useState();
  const breedRef = useRef({});
  const ageRef = useRef('');
  const [email, setEmail, isEmailValid] = useTargetState('', validateEmail);
  const [firstName, setFirstName, isFirstNameValid] = useTargetState('');
  const [lastName, setLastName, isLastNameValid] = useTargetState('');
  const [zipcode, setZipcode] = useTargetState('');
  const [shouldRegister, setShouldRegister] = useState();
  const [hasTriedToSubmit, setHasTriedToSubmit] = useState(false);

  // this endpoint returns a 404 if invalid input, so just forcing the data return to be consistent
  const { data: zipData = {} } = useZipcodes(zipcode);
  const isZipcodeValid = !!zipData.zip;

  const [debouncedEmail] = useDebounce(email, 500);
  const { data: emailData } = useQuoteEmail(debouncedEmail);
  const doesEmailExist = emailData.exists;

  const { data: vetData } = useVet(vetId);
  const { data: quotePetsData } = useQuote({ quoteId }, { select: getQuotePets });
  const {
    petName = 'your pet',
    petBreedSpecies,
    id: petId,
  } = quotePetsData[0] || {};
  const { data: breedsData } = useBreeds(petBreedSpecies);

  const { isLoading: isUpdateQuotePetLoading, mutateAsync: updateQuotePet } =
    useMutatePatchQuotePet();
  const { isLoading: isUpdateQuoteVetLoading, mutateAsync: updateQuoteVet } =
    useMutatePatchQuoteVet();
  const { isLoading: isRegisterQuoteLoading, mutateAsync: registerQuote } =
    useMutateRegisterQuote(quoteId);
  const { isLoading: isSendEmailLoading, mutateAsync: sendEmail } =
    useMutateSendPawsightsEmail();

  const vetName = vetData.vet_display_name || vetData.vet_name;
  const isLoading = [
    isUpdateQuotePetLoading,
    isUpdateQuoteVetLoading,
    isRegisterQuoteLoading,
    isSendEmailLoading,
  ].some(status => !!status);

  function formatEmailError() {
    if (doesEmailExist)
      return 'We see you’re already using this email for your Pumpkin account – please use a different email to receive your report.';
    if (!isEmailValid) return 'Please enter a valid email';

    return '';
  }

  function handleSubmit(e) {
    e.preventDefault();

    const isRegisterValid =
      typeof shouldRegister === 'boolean' &&
      ((shouldRegister && isFirstNameValid && isLastNameValid && isZipcodeValid) ||
        !shouldRegister);

    const payloadEmail = sessionEmail || email;

    if (
      !(gender && breedRef.current.code && ageRef.current && isRegisterValid) ||
      (!dawgs1906PawsightsEmailCapture && !!formatEmailError())
    ) {
      setHasTriedToSubmit(true);
    } else {
      updateQuotePet({
        quoteId,
        pet: {
          id: petId,
          petName,
          petBreedSpecies,
          petGender: gender,
          petAge: ageRef.current,
          petBreedCode: breedRef.current.code,
          petBreedName: breedRef.current.name,
          petBreedType: breedRef.current.type,
        },
      })
        .then(() => {
          return Promise.all([
            shouldRegister
              ? registerQuote({
                  quoteId,
                  quotePetId: petId,
                  firstName,
                  lastName,
                  email: payloadEmail,
                  zipcode,
                })
              : null,
            vetId ? updateQuoteVet({ quoteId, vetId }) : null,
            sendEmail({
              quoteId,
              vetId,
              breedCode: breedRef.current.code,
              breedName: breedRef.current.name,
              petName,
              email: payloadEmail,
            }),
          ]);
        })
        .then(() => {
          analyticsTrack({
            category: 'Pawsights',
            event: 'Step 2 form submit',
            label: shouldRegister ? 'Quote requested' : 'Quote not requested',
          });
          analyticsIdentify(
            shouldRegister
              ? { email: payloadEmail, firstName, lastName }
              : { email: payloadEmail }
          );

          dispatch(
            push({
              pathname: PATH_PAWSIGHTS_REPORT,
              search: `?quote_id=${quoteId}&breed_code=${
                breedRef.current.code
              }&vet_id=${vetId || ''}&pet_name=${petName}&${utms}`,
            })
          );
        });
    }
  }

  function handleBreedSelect({ label, value }) {
    breedRef.current = { code: value.code, name: label, type: value.type };
  }

  function handleAgeSelect({ value }) {
    ageRef.current = value;
  }

  return (
    <>
      <PawsightsNavBar vetName={vetName} />

      <div className={styles.content}>
        <Stepper
          classes={{ root: styles.stepperRoot, img: styles.stepperImg }}
          activeIndex={1}
          steps={[
            { icon: helloStep, text: 'Introduction' },
            { icon: pawStep, text: 'Pet Details' },
            {
              icon: reportStep,
              text: (
                <>
                  Get <span className={styles.desktop}>Your </span>Report
                </>
              ),
            },
          ]}
        />

        <form onSubmit={handleSubmit} disabled={isLoading}>
          <h3>{`Tell us all about ${petName}!`}</h3>

          <p className={styles.question}>
            Is {petName} a girl or a boy?<sup>*</sup>
          </p>

          <RadioGroup
            row
            onChange={setGender}
            value={gender}
            classes={{ root: styles.radioGroup }}
            error={{
              errorMessage:
                hasTriedToSubmit && !gender ? 'This field is required' : '',
            }}
          >
            <Radio
              classes={{
                root: styles.radio,
                label: styles.radioLabel,
                radio: styles.radioRadio,
                image: styles.radioImage,
                checked: styles.radioChecked,
              }}
              label={
                <>
                  <img src={icons[petBreedSpecies]?.girl} alt='' />
                  <p>Girl</p>
                </>
              }
              value='female'
              variant='portal'
              align='center'
            />
            <Radio
              classes={{
                root: styles.radio,
                label: styles.radioLabel,
                radio: styles.radioRadio,
                image: styles.radioImage,
                checked: styles.radioChecked,
              }}
              label={
                <>
                  <img src={icons[petBreedSpecies]?.boy} alt='' />
                  <p>Boy</p>
                </>
              }
              value='male'
              variant='portal'
              align='center'
            />
          </RadioGroup>

          <p id='question-age' className={styles.question}>
            How old is {petName}?<sup>*</sup>
          </p>

          <Select
            classes={{ wrapper: styles.select }}
            options={PET_AGE_OPTIONS}
            onChange={handleAgeSelect}
            placeholder='Select an age'
            id='id-select-age'
            aria-labelledby='question-age'
            error={{
              isErrorHidden: !(hasTriedToSubmit && !ageRef.current),
              errorMessage:
                hasTriedToSubmit && !ageRef.current
                  ? 'This field is required'
                  : null,
            }}
          />

          <p id='question-breed' className={styles.question}>
            What is {petName}'s primary breed?<sup>*</sup>
          </p>

          <Select
            isSearchable
            classes={{ wrapper: styles.select }}
            onChange={handleBreedSelect}
            aria-labelledby='question-breed'
            placeholder='Start typing and select from the list'
            options={breedsData.map(({ name, code, species, type }) => ({
              label: name,
              value: { code, species, type },
            }))}
            id='id-select-breed'
            error={{
              isErrorHidden: !(hasTriedToSubmit && !breedRef.current),
              errorMessage:
                hasTriedToSubmit && !breedRef.current.code
                  ? 'This field is required'
                  : null,
            }}
          />

          {!dawgs1906PawsightsEmailCapture ? (
            <>
              <p className={styles.question}>
                Where should we send your report?<sup>*</sup>
              </p>

              <TextField
                id='email'
                placeholder='Enter your email address'
                isLabelHidden
                value={email}
                onChange={setEmail}
                variant='portal'
                classes={{
                  container: styles.textContainer,
                }}
                legalMessage={
                  <p className={styles.legalPrivacy}>
                    We are committed to protecting your privacy. By submitting your
                    email, you are agreeing to our{' '}
                    <a
                      href={PATH_EXT_PRIVACY}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a
                      href={PATH_EXT_TERMS}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Terms & Conditions
                    </a>
                  </p>
                }
                error={{
                  errorMessage: hasTriedToSubmit && formatEmailError(),
                }}
              />
            </>
          ) : null}

          <p className={styles.question}>
            Would you like to see info about Pumpkin Pet Insurance plans, and how
            much it would cost for {petName}?<sup>*</sup>
          </p>

          <RadioGroup
            onChange={setShouldRegister}
            value={shouldRegister}
            classes={{ root: styles.radioGroup }}
            error={{
              errorMessage:
                hasTriedToSubmit && typeof shouldRegister !== 'boolean'
                  ? 'This field is required'
                  : '',
            }}
          >
            <Radio
              classes={{
                root: styles.radio,
                label: styles.radioLabel,
                radio: styles.radioRadio,
                image: styles.radioImage,
                checked: styles.radioChecked,
              }}
              label='Yes, please!'
              value
              variant='portal'
            />
            <Radio
              classes={{
                root: styles.radio,
                label: styles.radioLabel,
                radio: styles.radioRadio,
                image: styles.radioImage,
                checked: styles.radioChecked,
              }}
              label='No, thank you'
              value={false}
              variant='portal'
            />
          </RadioGroup>

          {shouldRegister ? (
            <div className={styles.bio}>
              <p className={styles.bioHelp}>
                The info you provide here will be used to create a custom pet
                insurance quote for {petName}.
              </p>

              <div className={styles.bioHalf}>
                <p className={styles.question}>
                  Your first name<sup>*</sup>
                </p>
                <TextField
                  id='first-name'
                  placeholder='Enter your first name'
                  isLabelHidden
                  value={firstName}
                  onChange={setFirstName}
                  variant='portal'
                  classes={{
                    container: styles.textContainer,
                  }}
                  error={{
                    errorMessage:
                      hasTriedToSubmit && !isFirstNameValid
                        ? 'This field is required'
                        : null,
                  }}
                />
              </div>

              <div className={styles.bioHalf}>
                <p className={styles.question}>
                  Your last name<sup>*</sup>
                </p>
                <TextField
                  id='last-name'
                  placeholder='Enter your last name'
                  isLabelHidden
                  value={lastName}
                  onChange={setLastName}
                  variant='portal'
                  classes={{
                    container: styles.textContainer,
                  }}
                  error={{
                    errorMessage:
                      hasTriedToSubmit && !isLastNameValid
                        ? 'This field is required'
                        : null,
                  }}
                />
              </div>

              <div className={styles.bioFull}>
                <p className={styles.question}>
                  Where do you & {petName} live?<sup>*</sup>
                </p>
                <TextField
                  id='zipcode'
                  placeholder='Enter ZIP code'
                  isLabelHidden
                  value={zipcode}
                  onChange={setZipcode}
                  variant='portal'
                  classes={{
                    container: styles.textContainer,
                  }}
                  error={{
                    errorMessage:
                      hasTriedToSubmit && !isZipcodeValid
                        ? 'Please enter a valid zipcode'
                        : null,
                  }}
                />
              </div>
            </div>
          ) : null}

          <p className={styles.legalPermission}>
            By selecting yes, you are giving us permission to generate and email you
            a pet insurance quote based on the info you provide, in addition to other
            helpful email communications.{' '}
            <a href='#footer'>Learn more about this here</a>
          </p>

          <ButtonPrimary
            type='submit'
            id='generate-report'
            className={styles.button}
            isLoading={isLoading}
          >
            Generate {petName}'s Report
          </ButtonPrimary>
        </form>
      </div>

      <PawsightsContact vetName={vetName} />
      <PawsightsFooter />
    </>
  );
}

export default PawsightsForm;
