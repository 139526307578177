import { USER_AGENTS } from '@pumpkincare/portal-shared';

import { CONTACT_PAWSIGHTS_PHONE } from '../../../../app';

import styles from './contact.css';

import call from 'images/illustrations/misc/call.png';
import customerService from 'images/illustrations/misc/customer-service.png';
import chat from 'images/illustrations/misc/live-chat.png';
import text from 'images/illustrations/misc/text.png';

const contactItems = [
  {
    key: 'call',
    icon: call,
    title: !USER_AGENTS.desktop ? (
      <a className={styles.linkText} href={`tel:${CONTACT_PAWSIGHTS_PHONE}`}>
        Call {CONTACT_PAWSIGHTS_PHONE} (PAWS)
      </a>
    ) : (
      `Call ${CONTACT_PAWSIGHTS_PHONE} (PAWS)`
    ),
    subtitle: 'Mon-Fri 8am-8pm EST',
  },
  {
    key: 'text',
    icon: text,
    title: !USER_AGENTS.desktop ? (
      <a className={styles.linkText} href={`sms:${CONTACT_PAWSIGHTS_PHONE}`}>
        Text {CONTACT_PAWSIGHTS_PHONE} (PAWS)
      </a>
    ) : (
      `Text ${CONTACT_PAWSIGHTS_PHONE} (PAWS)`
    ),
    subtitle: 'Mon-Fri 8am-8pm EST',
  },
  {
    key: 'chat',
    icon: chat,
    title: (
      <a
        className={styles.linkText}
        role='button'
        onClick={() => {
          window.GladlyHelpApp.show();
        }}
      >
        Live Chat
      </a>
    ),
    subtitle: 'Mon-Fri 9am-5pm EST',
  },
];

function Contact({ vetName }) {
  return (
    <div className={styles.root}>
      <div className={styles.customerService}>
        <img alt='' src={customerService} />
      </div>
      <h2>Have questions about your report or pet insurance?</h2>
      <p className={styles.subtitle}>
        As a client of {vetName} you get access to Pumpkin’s team of pet specialists
        and insurance experts.
      </p>

      <div className={styles.contactItems}>
        {contactItems.map(contactItem => (
          <div className={styles.contactItem} key={contactItem.key}>
            <img alt='' src={contactItem.icon} />
            <div className={styles.contactItemText}>
              <p>
                <b>{contactItem.title}</b>
              </p>
              <p>{contactItem.subtitle}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Contact;
