export const PATH_HOME = '/';
export const PATH_REGISTER = '/register';
export const PATH_LOGIN = '/login';
export const PATH_REQUEST_RESET = '/forgot-password';
export const PATH_RESET_PASSWORD = '/reset-password';
export const PATH_UNSUBSCRIBE = '/unsubscribe';

export const PATH_PREVENTIVE_ESSENTIALS = '/preventive-essentials';

export const PATH_ACCOUNT_BASE = '/account';
export const PATH_ACCOUNT_PROFILE = '/account/profile';

export const PATH_CUSTOMER_BASE = '/customer-visit';
export const PATH_CUSTOMER_VISIT = '/customer-visit/:visitId/vets/:vetId';

export const PATH_PATIENTS_BASE = '/patients';
export const PATH_PATIENT_VIEW = '/patients/:patientId';

export const PATH_PATIENT_PET_CLAIMS = '/patients/:patientId/claims/:petId';

export const PATH_FILE_CLAIM_BASE = '/file-claim';
export const PATH_FILE_CLAIM_SUCCESS = '/claim-success';

export const PATH_PHOTO_CARD_GALLERY = '/photo-cards';

export const PATH_CUSTOMER_PLANS = '/customer/plans';

export const PATH_PAWSIGHTS_BASE = '/pawsights';
export const PATH_PAWSIGHTS_LANDING = '/pawsights/landing';
export const PATH_PAWSIGHTS_FORM = '/pawsights/form';
export const PATH_PAWSIGHTS_REPORT = '/pawsights/report';

export const PATH_DASHBOARD = '/dashboard';

export const PATH_RECORDS_REQUEST = '/records-request/:recordRequestId';

// "external" paths
export const PATH_PUMPKIN = 'https://www.pumpkin.care';
export const PATH_EXT_TOOLKIT = `${PATH_PUMPKIN}/toolkit`;
export const PATH_EXT_TOOLKIT_NONPEP = `${PATH_PUMPKIN}/toolkit/insurance`;
export const PATH_EXT_BLOG = `https://www.pumpkin.pet/blog`;
export const PATH_EXT_CALIFORNIA_NOTICE = `${PATH_PUMPKIN}/notice-to-california-residents/`;
export const PATH_EXT_MAINE_NOTICE = `${PATH_PUMPKIN}/notice-to-maine-residents/`;
export const PATH_EXT_INSURANCE_POLICY = `${PATH_PUMPKIN}/insurancepolicy`;
export const PATH_EXT_CUSTOMER_AGREEMENT = `${PATH_PUMPKIN}/customeragreement`;
export const PATH_EXT_UNDERWRITING = `${PATH_PUMPKIN}/underwriting-information`;
export const PATH_EXT_TERMS = `${PATH_PUMPKIN}/terms-of-use`;
export const PATH_EXT_PRIVACY = `${PATH_PUMPKIN}/privacy-policy`;
export const PATH_EXT_COOKIES = `${PATH_PUMPKIN}/cookies-policy`;
export const PATH_EXT_CAT_INSURANCE = `${PATH_PUMPKIN}/cat-health-insurance`;
export const PATH_EXT_DOG_INSURANCE = `${PATH_PUMPKIN}/dog-health-insurance`;
export const PATH_EXT_QUOTE_FLOW = `${PATH_PUMPKIN}/quote/intro`;
export const PATH_EXT_SAMPLE_PLAN = `${PATH_PUMPKIN}/sample-plan`;
export const PATH_EXT_DVM_ACCESS = 'https://dvm.pumpkin.care/pawportal-access';
export const PATH_TERMS =
  'https://pumpkin-assets.s3.amazonaws.com/pdfs/paw-portal-terms-of-use.pdf';
export const PATH_MARVEL = `${PATH_PUMPKIN}/themarvels/?utm_source=vetmktPet&utm_medium=photocard&utm_campaign=marvel_vet&utm_content=marvel_photo`;

// photo-submission
export const QUERY_VIEWS = [
  'frame-selection',
  'patient-form',
  'preview-submit',
  'submit-success',
];

// collections of paths (e.g. customer facing paths only, paths that need to use matchPath)
export const PUBLIC_PATHS = [
  PATH_CUSTOMER_VISIT,
  PATH_UNSUBSCRIBE,
  PATH_CUSTOMER_PLANS,
  PATH_PAWSIGHTS_BASE,
  PATH_PAWSIGHTS_LANDING,
  PATH_PAWSIGHTS_FORM,
  PATH_PAWSIGHTS_REPORT,
];
export const ROUTE_PARAM_PATHS = [
  PATH_CUSTOMER_VISIT,
  PATH_PATIENT_VIEW,
  PATH_PATIENT_PET_CLAIMS,
];
