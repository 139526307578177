import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { push } from 'connected-react-router';

import { trackPhotoCardGalleryModal } from '@pumpkincare/portal-analytics';
import { useNavContext } from '@pumpkincare/portal-app';
import {
  formatISODate,
  formatSearchFromQuery,
  useTargetState,
  useToggleState,
} from '@pumpkincare/portal-shared';
import {
  Body1,
  Body2,
  BodySubhead,
  Button,
  ButtonSecondary,
  InfiniteScroll,
  Modal,
  Processing,
  SearchField,
} from '@pumpkincare/portal-ui';

import { getRouterLocation } from '../../../app';
import { PATH_HOME } from '../../../app/routing/paths';
import useLoadVisitsPhotos from '../../hooks/use-load-visits';
import PhotoCardModal from '../photo-card-modal';
import PhotoTool from '../photo-tool';

import styles from './photo-card-gallery.css';

import cameraIcon from 'images/icons/camera.svg';
import camera from 'images/illustrations/misc/camera.png';
import multiPetMail from 'images/illustrations/multipet/mail.png';

export default function PhotoCardGallery() {
  const dispatch = useDispatch();
  const [modalData, setModalData] = useState(null);
  const [isPhotoToolModelOpen, toggleIsPhotoToolModelOpen] = useToggleState(false);

  const { loadNewPage, loadNewSearch, statuses, totalPhotos, visitsPhotos } =
    useLoadVisitsPhotos();

  const { pathname, query } = useSelector(getRouterLocation);
  const { isNavBarOpen } = useNavContext();
  const { isLoading, isDone, isSuccess } = statuses;
  const [keyword, setKeyword] = useTargetState(query.keyword || '');
  const hasInitialized = useRef(false);

  useEffect(() => {
    if (!hasInitialized.current) {
      hasInitialized.current = true;
      loadNewSearch(keyword);
    }
  }, [keyword, loadNewSearch, hasInitialized]);

  function handleSearch(e) {
    const { value } = e.target;
    const newQuery = { ...query };

    if (value) {
      const encodedValue = encodeURIComponent(value);
      loadNewSearch(encodedValue);
      newQuery.keyword = encodedValue;
    } else {
      loadNewSearch('');
      delete newQuery.keyword;
    }

    dispatch(
      push({
        pathname,
        search: formatSearchFromQuery(newQuery),
      })
    );
  }

  function handleOpen(data) {
    setModalData(data);
    trackPhotoCardGalleryModal(data.visit.id);
  }

  function handleClose() {
    setModalData(null);
  }

  function handleScroll() {
    loadNewPage(keyword);
  }

  return (
    <div className={styles.container}>
      <h3>Photo Cards</h3>

      <Button onClick={toggleIsPhotoToolModelOpen} className={styles.newCardButton}>
        <img src={cameraIcon} alt='' />
        Send New Card
      </Button>

      <SearchField
        value={keyword}
        onChange={setKeyword}
        onSearch={handleSearch}
        isLoading={isLoading}
        label='Search by Client Name, Email, Pet Name'
        classes={{
          container: classNames(styles.searchContainer, {
            [styles.nav]: isNavBarOpen,
          }),
          text: styles.searchText,
        }}
      />

      <BodySubhead className={styles.cardsSent}>
        {totalPhotos > 0 || isSuccess ? (
          `${totalPhotos} Card${totalPhotos === 1 ? '' : 's'} Sent`
        ) : (
          <Processing classes={{ container: styles.loading, ring: styles.ring }} />
        )}
      </BodySubhead>

      <div className={styles.content}>
        {!isLoading && !visitsPhotos?.length ? (
          <div className={styles.noResults}>
            {keyword ? (
              <Body1 className={styles.noSearchResults}>
                No matching images found. Check you entered the pet or client
                information correctly and try again.
              </Body1>
            ) : (
              <>
                <img
                  role='presentation'
                  src={multiPetMail}
                  alt='cat and dog with mail'
                  className={styles.image}
                />
                <Body1>
                  It looks like you haven't sent any photo cards yet! Head to the
                  homepage to send your first card.
                </Body1>
                <Link to={PATH_HOME} className={styles.link}>
                  <ButtonSecondary className={styles.button}>
                    Visit Homepage
                  </ButtonSecondary>
                </Link>
              </>
            )}
          </div>
        ) : null}

        {visitsPhotos ? (
          <InfiniteScroll
            onScrollToBottom={handleScroll}
            isLoading={isLoading}
            isDone={isDone}
          >
            <div className={styles.photoGallery}>
              {visitsPhotos.map(result => (
                <Button
                  key={result.photo.id}
                  onClick={() => handleOpen(result)}
                  className={styles.visitButton}
                >
                  <img src={result.photo.merged_signed_url} alt={"pet's visit"} />
                  <BodySubhead style={{ marginTop: '12px', fontSize: '16px' }}>
                    {result.visit.pet_name}
                  </BodySubhead>
                  <Body2 style={{ marginTop: '6px' }}>
                    {formatISODate(result.visit.created_at)}
                  </Body2>
                  <Body1>{`${result.visit.client_first_name} ${result.visit.client_last_name}`}</Body1>
                </Button>
              ))}
            </div>
          </InfiniteScroll>
        ) : null}
      </div>

      {modalData ? <PhotoCardModal onClose={handleClose} data={modalData} /> : null}

      {isPhotoToolModelOpen ? (
        <Modal
          onClose={toggleIsPhotoToolModelOpen}
          classes={{ container: styles.photoToolModal }}
        >
          <div className={styles.photoToolContainer}>
            <h4 className={styles.photoToolHeader}>
              <img src={camera} alt='' />
              Send a Pet Photo Card
            </h4>
            <Body1 className={styles.photoToolSubheader}>
              Take or Upload a Photo
            </Body1>

            <PhotoTool
              onClose={toggleIsPhotoToolModelOpen}
              returnCopy='Return to Gallery'
            />
          </div>
        </Modal>
      ) : null}
    </div>
  );
}
