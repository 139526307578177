import { Link } from 'react-router-dom';
import Store from 'store';

import { useDashboardMemes, useDashboardVets } from '@pumpkincare/portal-dashboard';
import { ButtonPrimary } from '@pumpkincare/portal-ui';
import {
  useMutatePatchVetInitiative,
  useVetInitiatives,
} from '@pumpkincare/portal-vets';

import {
  DASHBOARD_INDEXES,
  MEME_INDEX,
} from '../../../dashboard/constants/local-storage';
import { PATH_DASHBOARD } from '../../routing';

import styles from './dashboard-opt-in.css';

import meme from './meme.png';
import tablet from './tablet.png';
import checkIcon from 'images/icons/check-gray.svg';

function DashboardOptIn({ vetId }) {
  const { data: vetInitiatives } = useVetInitiatives({
    vetId,
  });
  const dashboardVetId = vetId.replace(/-/g, ''); // DWH vet_ids don't have "-"
  const { isLoading: isPatchVetInitiativeLoading, mutateAsync: patchVetInitiative } =
    useMutatePatchVetInitiative(vetId, vetInitiatives.id);

  const { error: vetError, isPlaceholderData: isVetPlaceholderData } =
    useDashboardVets(dashboardVetId);
  const hasVetData = !isVetPlaceholderData && !vetError;
  const memeIndex = Store.get(DASHBOARD_INDEXES)?.[MEME_INDEX] || 0;
  const { data: memeData, isPlaceholderData: isMemePlaceholderData } =
    useDashboardMemes(memeIndex);

  function handleClick() {
    if (!vetInitiatives.has_dashboard) {
      patchVetInitiative({ hasDashboard: true });
    }
  }

  const title =
    vetInitiatives.has_dashboard && hasVetData
      ? 'Access Pumpkin Dashboard'
      : 'Request Access to Pumpkin Dashboard';

  const subtitle =
    vetInitiatives.has_dashboard && hasVetData
      ? 'Check out daily memes, quizzes, and fun facts and start tracking your clinic’s Pawsights scans, quotes, and policies.'
      : 'Receive daily memes, quizzes, and fun facts from Pumpkin. Also, track your clinic’s Pawsights scans, quotes, and policies all in one place.';

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.contentLeft}>
          <h4 className={styles.title}>{title}</h4>
          <h4 className={styles.subtitle}>{subtitle}</h4>

          <div className={styles.requestItems}>
            <ButtonPrimary
              className={styles.button}
              styledDisabled={
                (vetInitiatives.has_dashboard && !hasVetData) ||
                isPatchVetInitiativeLoading
              }
              disabled={
                (vetInitiatives.has_dashboard && !hasVetData) ||
                isPatchVetInitiativeLoading
              }
              onClick={handleClick}
            >
              {vetInitiatives.has_dashboard ? (
                hasVetData ? (
                  <Link
                    to={`${PATH_DASHBOARD}?vet_id=${dashboardVetId}`}
                    target='_blank'
                    className={styles.link}
                  >
                    View Dashboard
                  </Link>
                ) : (
                  <div className={styles.buttonText}>
                    <img src={checkIcon} alt='check' />
                    Requested
                  </div>
                )
              ) : (
                'Request Now'
              )}
            </ButtonPrimary>

            {vetInitiatives.has_dashboard && !hasVetData ? (
              <span className={styles.requestedText}>
                You will get access in 24 hours
              </span>
            ) : null}
          </div>
        </div>

        {vetInitiatives.has_dashboard && hasVetData ? (
          <div className={styles.memeContainer}>
            <img className={styles.memeText} src={meme} alt='Meme of the day text' />
            {!isMemePlaceholderData ? (
              <img
                className={styles.meme}
                src={memeData.url}
                alt='Meme of the day'
              />
            ) : null}
          </div>
        ) : (
          <img className={styles.image} src={tablet} alt='Dashboard on Tablet' />
        )}
      </div>
    </div>
  );
}

export default DashboardOptIn;
