import { SALES_EMAIL } from '../../../app';

import styles from './vet-discount.css';

import piggyBank from 'images/illustrations/misc/piggy-bank.png';

function VetDiscount() {
  return (
    <div className={styles.root}>
      <img src={piggyBank} alt='' className={styles.img} />

      <h4>Take Advantage of Up to 20% Discount on Pumpkin Pet Insurance Plans!</h4>

      <div className={styles.discount}>
        <p className={styles.number}>10%*</p>
        <p>Employee discount on Pumpkin plans</p>
      </div>

      <div className={styles.discount}>
        <p className={styles.number}>10%</p>
        <p>Multi-pet discount for each additional pet</p>
      </div>

      <p className={styles.contact}>
        Contact your rep today or email <b>{SALES_EMAIL}</b> for more info.
      </p>

      <div className={styles.block}>
        <h5>Disclaimer</h5>
        <p>
          *Discounts may vary, may not be available in all states, and are subject to
          change.
        </p>
      </div>
    </div>
  );
}

export default VetDiscount;
