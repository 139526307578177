import classNames from 'classnames';

import styles from './aside.css';

import logo from 'images/logos/pumpkin.svg';

function Aside({ children, viewIdx = 0, isLegal = false, numBubbles }) {
  const steps = Array(numBubbles).fill();

  return (
    <div className={styles.root}>
      {!isLegal ? <img src={logo} alt='' className={styles.logo} /> : null}

      {!isLegal ? (
        <div data-testid='aside-card' className={styles.card}>
          {children}
        </div>
      ) : (
        children
      )}

      <ul className={styles.dots}>
        {steps.map((step, stepIdx) => (
          <div
            key={`step-${stepIdx}`}
            role='listitem'
            className={classNames(styles.dot, {
              [styles.active]: stepIdx === viewIdx,
            })}
          />
        ))}
      </ul>
    </div>
  );
}

export default Aside;
