import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ERROR, LOADING, SUCCESS } from '@pumpkincare/portal-shared';
import { Body1, ButtonSecondary } from '@pumpkincare/portal-ui';

import { PATH_ACCOUNT_BASE } from '../../routing';
import { getAppResetPasswordStatus, getRouterLocationQuery } from '../../selectors';
import changePasswordLoggedOut from '../../thunks/change-password-logged-out';
import ResetPasswordForm from '../reset-password-form';

import styles from './reset-password-page.css';

import check from 'images/icons/check-circle-green.svg';

export default function ResetPasswordPage() {
  const dispatch = useDispatch();
  const status = useSelector(getAppResetPasswordStatus);

  const query = useSelector(getRouterLocationQuery);
  const { token } = query;
  const email = useMemo(() => decodeURIComponent(query.email), [query]);

  function handleSubmit({ password }) {
    dispatch(changePasswordLoggedOut(token, email, password));
  }

  return (
    <div className={styles.container}>
      {status === SUCCESS ? (
        <>
          <h4 className={styles.title}>
            <img
              src={check}
              alt='check'
              role='presentation'
              className={styles.success}
            />
            Your Password Was Reset
          </h4>
          <Body1>Purr-ay! You've successfully reset your password.</Body1>
          <Link to={PATH_ACCOUNT_BASE} style={{ textDecoration: 'none' }}>
            <ButtonSecondary className={styles.button}>
              Go to PawPortal Home
            </ButtonSecondary>
          </Link>
        </>
      ) : (
        <ResetPasswordForm
          isLoading={status === LOADING}
          hasError={status === ERROR}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
}
