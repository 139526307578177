import { Link } from 'react-router-dom';

import { Body1 } from '@pumpkincare/portal-ui';

import { formatClaimRedirect } from '../../utils';

import styles from './no-claims.css';

import dogCatBox from 'images/illustrations/multipet/box.png';

export default function NoClaims({ hasActivePolicy, patientId, petId }) {
  return (
    <>
      <img
        src={dogCatBox}
        alt='dog and cat with a box'
        role='presentation'
        style={{ margin: '32px auto' }}
      />
      <Body1 className={styles.content}>
        It looks like no Pumpkin claims have been filed for this patient yet.
      </Body1>
      {hasActivePolicy ? (
        <Link to={formatClaimRedirect(patientId, petId)} className={styles.link}>
          Start a Claim
        </Link>
      ) : null}
    </>
  );
}
