import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { replace } from 'connected-react-router';

import {
  trackSocialDropdown,
  trackViewPreview,
} from '@pumpkincare/portal-analytics';
import { usePlans, useVetProducts } from '@pumpkincare/portal-app';
import {
  A,
  Body1,
  ButtonPrimary,
  ButtonSecondary,
  PreviewIframe,
  Tooltip,
} from '@pumpkincare/portal-ui';
import { getVetSelfVetId, useVetSelf } from '@pumpkincare/portal-user';
import {
  getVetDisplayName,
  getVetState,
  useVet,
  useVetInitiatives,
} from '@pumpkincare/portal-vets';

import { PhotoTool } from '../../../visit';
import { DENTAL_HEALTH_CARD } from '../../constants';
import usePrintouts from '../../hooks/use-printouts';
import {
  PATH_ACCOUNT_BASE,
  PATH_EXT_BLOG,
  PATH_EXT_TOOLKIT,
  PATH_EXT_TOOLKIT_NONPEP,
  PATH_PATIENTS_BASE,
} from '../../routing';
import DashboardOptIn from '../dashboard-opt-in';
import HeroHeader from '../hero-header';
import PEPSectionCard from '../pep-section-card';
import SectionCard from '../section-card';
import SocialShareOptions from '../social-share-options';

import styles from './account-home.css';

import pumpkinIcon from 'images/icons/pumpkin.png';
import shareIcon from 'images/icons/upload.svg';
import camera from 'images/illustrations/misc/camera.png';
import chartInactive from 'images/illustrations/misc/chart-inactive.png';
import letter from 'images/illustrations/misc/letter.png';
import bag from 'images/illustrations/misc/medical-bag.png';
import paw from 'images/illustrations/misc/paw.png';
import search from 'images/illustrations/misc/search.png';
import heart from 'images/illustrations/shapes/small-heart.svg';

function AccountHome() {
  const dispatch = useDispatch();
  const [previewContent, setPreviewContent] = useState({});
  const { data: vetId } = useVetSelf({ select: getVetSelfVetId });
  const {
    data: plans,
    isFetched: isPlansFetched,
    isError: isPlansError,
  } = usePlans(vetId);
  const { data: printouts } = usePrintouts(vetId);
  const { data: vetData } = useVet(vetId);
  const { data: productsData, isFetched: isProductsFetched } = useVetProducts(
    getVetState(vetData)
  );
  const { isPlaceholderData: isVetInitiativeLoading } = useVetInitiatives({
    vetId,
  });

  const vetDisplayName = getVetDisplayName(vetData);
  const isPEPLoading = !isProductsFetched;
  const isPEPEnabled = productsData.prevent;

  const showPEPSectionCard =
    isPEPEnabled && isPlansFetched && !isPlansError && vetDisplayName;

  useEffect(() => {
    dispatch(replace({ pathname: PATH_ACCOUNT_BASE }));
  }, [dispatch]);

  return (
    <>
      <HeroHeader
        text={`Welcome${vetDisplayName ? `, ${vetDisplayName}` : ''}!`}
        horizontal
      />

      <div className={styles.container}>
        <img src={heart} className={styles.heart} alt='heart' role='presentation' />

        {!isVetInitiativeLoading ? (
          <div className={styles.desktopOnly}>
            <DashboardOptIn vetId={vetId} />
          </div>
        ) : null}

        {!isPEPLoading ? (
          <>
            {showPEPSectionCard ? (
              <>
                <h4 className={styles.imageHeader} style={{ marginBottom: '0' }}>
                  <img src={pumpkinIcon} alt='' className={styles.headerCamera} />
                  Share Pumpkin Info
                </h4>
                <PEPSectionCard
                  vetDisplayName={vetDisplayName}
                  vetId={vetId}
                  plans={plans}
                  printouts={printouts}
                />
              </>
            ) : null}

            <h4 className={styles.imageHeader} style={{ marginBottom: '0' }}>
              <img src={letter} alt='' className={styles.headerCamera} />
              Send Treats
            </h4>
            <SectionCard
              category='Send Treats'
              content={DENTAL_HEALTH_CARD}
              vetName={vetDisplayName}
              tertiaryButton={{
                tertiaryTitle: 'Share to Social',
                tertiaryIcon: shareIcon,
                tertiaryOptions: (
                  <SocialShareOptions
                    content={DENTAL_HEALTH_CARD}
                    vetName={vetDisplayName}
                  />
                ),
                trackTertiary: () =>
                  trackSocialDropdown('Send Treats', DENTAL_HEALTH_CARD.name),
              }}
              actionText='Preview Guide'
              handleAction={() => {
                trackViewPreview('Send Treats', DENTAL_HEALTH_CARD.name);
                setPreviewContent(DENTAL_HEALTH_CARD);
              }}
            />
          </>
        ) : null}

        <h4 className={styles.imageHeader}>
          <img
            src={camera}
            alt='title-camera'
            role='presentation'
            className={styles.headerCamera}
          />
          Send a Pet Photo Card
        </h4>
        <Body1>Take or Upload a Photo</Body1>

        <PhotoTool />
      </div>
      <div className={styles.blueContainer}>
        <div className={styles.container}>
          <div className={styles.module}>
            <h4 className={styles.imageHeader}>
              <img
                src={bag}
                className={styles.moduleIcon}
                alt='bag'
                role='presentation'
              />
              Hospital Toolkit
            </h4>
            <Body1>
              Get marketing materials & resources to help you educate your clients
              about pet insurance and preventive care.
            </Body1>

            {isPEPLoading ? (
              <div className={styles.link}>
                <ButtonSecondary className={styles.button} isLoading />
              </div>
            ) : (
              <A
                href={isPEPEnabled ? PATH_EXT_TOOLKIT : PATH_EXT_TOOLKIT_NONPEP}
                className={styles.link}
                target='_blank'
              >
                <ButtonSecondary className={styles.button}>
                  Visit Toolkit
                </ButtonSecondary>
              </A>
            )}
          </div>

          <div className={styles.module}>
            <h4 className={styles.imageHeader}>
              <img
                src={search}
                className={styles.moduleIcon}
                alt='search'
                role='presentation'
              />
              Prrs&Wags Blog
            </h4>
            <Body1>
              Browse our blog for fresh content to share with clients about pet
              health, fun stuff, and everything in between.
            </Body1>
            <A href={PATH_EXT_BLOG} className={styles.link} target='_blank'>
              <ButtonSecondary className={styles.button}>
                Visit the Blog
              </ButtonSecondary>
            </A>
          </div>

          <div className={styles.module}>
            <h4 className={styles.imageHeader}>
              <img
                src={paw}
                className={styles.moduleIcon}
                alt='paw'
                role='presentation'
              />
              Pumpkin Patients
            </h4>
            <Body1>
              View key information about your Pumpkin patients, including medical
              records, claims, and benefit utilization.
            </Body1>
            <Link to={PATH_PATIENTS_BASE} className={styles.link}>
              <ButtonSecondary className={styles.button}>
                Visit Patients
              </ButtonSecondary>
            </Link>
          </div>

          <div className={styles.module}>
            <h4 className={classNames(styles.imageHeader, styles.disabledText)}>
              <img
                src={chartInactive}
                className={styles.moduleIcon}
                alt='chart'
                role='presentation'
              />
              Practice Insights
            </h4>
            <Body1 className={styles.disabledText}>
              Access helpful claim data, pet health trends, and track the impact of
              Pumpkin patients on your practice.
            </Body1>
            <div className={styles.link}>
              <Tooltip content='This feature is not available yet. Stay tuned!'>
                <ButtonPrimary className={styles.button} styledDisabled>
                  Coming Soon
                </ButtonPrimary>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>

      {previewContent?.url ? (
        <PreviewIframe
          content={previewContent.url}
          name={previewContent.name}
          onClose={() => setPreviewContent({})}
        />
      ) : null}
    </>
  );
}

export default AccountHome;
