import { PATH_EXT_INSURANCE_POLICY } from '../../../app';

import styles from './records-request-footer.css';

import heart from 'images/illustrations/shapes/heart-huckblue.svg';

function RecordsRequestFooter({ classes = {} }) {
  return (
    <div className={classes.root}>
      <div className={styles.wave}>
        <img src={heart} alt='' />
      </div>
      <footer className={styles.footer}>
        <p>
          Pumpkin Pet Insurance policies do not cover pre-existing conditions.
          Waiting periods, annual deductible, co-insurance, benefit limits and
          exclusions may apply. For full terms, visit{' '}
          <a href={PATH_EXT_INSURANCE_POLICY} target='_blank' rel='noreferrer'>
            pumpkin.care/insurancepolicy
          </a>
          . Products and rates may vary and are subject to change. Discounts may vary
          and are subject to change.
        </p>

        <p>
          Pumpkin Insurance Services Inc. ("Pumpkin") (NPN#19084749) is a licensed
          insurance agency, not an insurer. Insurance is underwritten by United
          States Fire Insurance Company (NAIC #21113. Morristown, NJ), a Crum &
          Forster Company and produced by Pumpkin. Pumpkin receives compensation
          based on the premiums for the insurance policies it sells. For more details
          visit{' '}
          <a href={PATH_EXT_INSURANCE_POLICY} target='_blank' rel='noreferrer'>
            pumpkin.care/underwriting-information
          </a>{' '}
          and{' '}
          <a href={PATH_EXT_INSURANCE_POLICY} target='_blank' rel='noreferrer'>
            https://www.pumpkin.care/insurance-licenses
          </a>
        </p>

        <p>
          Pumpkin Preventive Essentials is not an insurance policy. It is offered as
          an optional add-on non-insurance benefit. Pumpkin is responsible for the
          product and administration. For full terms, visit{' '}
          <a href={PATH_EXT_INSURANCE_POLICY} target='_blank' rel='noreferrer'>
            pumpkin.care/customeragreement
          </a>
          . Pumpkin Preventive Essentials is not available in all states.
        </p>
      </footer>
    </div>
  );
}

export default RecordsRequestFooter;
