import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { IDLE } from '@pumpkincare/portal-shared';
import { A, Body1, ButtonPrimary, SubText1 } from '@pumpkincare/portal-ui';

import { PATH_FILE_CLAIM_BASE } from '../../../app';
import {
  CONTACT_PHONE,
  CONTACT_PHONE_ARFMEOW,
  CONTACT_PHONE_LINK,
  HELP_EMAIL,
  HELP_EMAIL_LINK,
} from '../../../app/constants';
import { getClaimSubmit } from '../../selectors';
import { setClaimSubmit } from '../../state';

import styles from './claim-success.css';

import checkIcon from 'images/icons/check-circle-green.svg';

export default function ClaimSuccess() {
  const dispatch = useDispatch();
  const claim = useSelector(getClaimSubmit);

  useEffect(() => {
    return () => dispatch(setClaimSubmit({ data: null, status: IDLE }));
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <img src={checkIcon} alt='camera' className={styles.icon} />
        <h4>Claim Submitted</h4>
      </div>
      <div className={styles.notesContainer}>
        <Body1 className={styles.notes}>
          Your client's claim has been submitted successfully.
        </Body1>
        <Body1 className={styles.notes}>
          Our claims team will process your claim and update you once our review is
          complete.
        </Body1>
        <Body1 className={styles.notes}>
          Should you have any questions along the way, contact our Care Team at{' '}
          <A href={CONTACT_PHONE_LINK}>{CONTACT_PHONE_ARFMEOW}</A> ({CONTACT_PHONE})
          or <A href={HELP_EMAIL_LINK}>{HELP_EMAIL}</A> – we’re here to help.
        </Body1>
      </div>
      <ButtonPrimary className={styles.button}>
        <Link to={PATH_FILE_CLAIM_BASE} className={styles.link}>
          Start a New Claim
        </Link>
      </ButtonPrimary>

      {claim?.send_reimbursement_to_vet ? (
        <SubText1 className={styles.reimbursementNote}>
          The insurance policy is a contract between the policyholder and the
          insurance carrier. Your client is financially responsible to you for
          services provided including those that are not eligible for reimbursement.
          Your client’s authorization for claims to be reimbursed directly to you may
          be revoked at any time.
        </SubText1>
      ) : null}
    </div>
  );
}
