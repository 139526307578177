import { useToggleState } from '@pumpkincare/portal-shared';
import { Button, Popover, Radio, RadioGroup } from '@pumpkincare/portal-ui';

import styles from './patient-filter.css';

import filterIcon from 'images/icons/filter.svg'; // eslint-disable-line import/no-unresolved

export default function PatientFilter({
  options,
  selected,
  onChangeHandler = () => {},
}) {
  const [isFilterOpen, toggleFilter] = useToggleState(false);

  function filterChanged(value) {
    toggleFilter();
    onChangeHandler(value);
  }

  // TODO: need to update backend to return the short label
  function getSelectedOptionShortLabel() {
    switch (selected) {
      case 'all':
        return 'All Patients';
      case 'active':
        return 'Active Policy';
      case 'inactive':
        return 'No Active Policy';
      default:
        return '';
    }
  }

  return (
    <div className={styles.filterContainer}>
      <Button className={styles.filterButton} onClick={toggleFilter}>
        <img className={styles.icon} src={filterIcon} alt='filter icon' />
        Filter: {getSelectedOptionShortLabel()}
      </Button>
      {isFilterOpen ? (
        <Popover className={styles.popover} onOutsideClick={toggleFilter}>
          <RadioGroup name='filter' value={selected} onChange={filterChanged}>
            {options?.map(option => {
              const value = Object.keys(option)[0];
              return <Radio label={option[value]} value={value} key={value} small />;
            })}
          </RadioGroup>
        </Popover>
      ) : null}
    </div>
  );
}
