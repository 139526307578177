import {
  Body1,
  ButtonPrimary,
  ButtonSecondary,
  Modal,
} from '@pumpkincare/portal-ui';

import useAddPatient from '../../hooks/use-add-patient';

import styles from './request-permission-modal.css';

import check from 'images/icons/check-circle-green.svg';

export default function RequestPermission({
  patientEmail,
  patientFirstName,
  patientLastName,
  successButtonText,
  onSuccessClick = null,
  onClose,
}) {
  const { email, requestPatient, statuses } = useAddPatient(patientEmail);

  return (
    <Modal
      onClose={onClose}
      classes={{ container: styles.container, content: styles.content }}
    >
      {statuses.isSuccess ? (
        <>
          <h4 className={styles.title}>
            <img
              src={check}
              alt='check'
              role='presentation'
              className={styles.check}
            />
            Request Sent
          </h4>
          <Body1 className={styles.copy}>
            {patientFirstName} will receive an email shortly inviting them to grant
            your hospital permission to file claims on their behalf. You will receive
            an email once they’ve accepted your request.
          </Body1>
          <ButtonSecondary
            onClick={onSuccessClick || onClose}
            className={styles.button}
          >
            {successButtonText || 'Close'}
          </ButtonSecondary>
        </>
      ) : (
        <>
          <h4 className={styles.title}>Request Permission</h4>
          <Body1 className={styles.copy}>
            It looks like{' '}
            <b>
              {patientFirstName} {patientLastName} ({patientEmail})
            </b>{' '}
            hasn’t granted your hospital permission to file claims on their behalf.
            Email them an invite to update their Pumpkin account and give you
            permission to file claims on their behalf.
          </Body1>
          <form onSubmit={requestPatient}>
            <ButtonPrimary
              type='submit'
              isLoading={statuses.isLoading}
              className={styles.button}
              disabled={!email}
            >
              Send Invite
            </ButtonPrimary>
          </form>
          {statuses.isError ? (
            <Body1 className={styles.error}>
              Sorry, something went wrong! Please try again later or reach out to our
              care team.
            </Body1>
          ) : null}
        </>
      )}
    </Modal>
  );
}
