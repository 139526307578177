import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider, QueryObserver } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { matchPath } from 'react-router';
import { Auth } from '@aws-amplify/auth';
import { ConnectedRouter } from 'connected-react-router';

import {
  analyticsIdentify,
  runQueuedAnalytics,
} from '@pumpkincare/portal-analytics';
import { formatLaunchDarklyUser } from '@pumpkincare/portal-app';
import {
  configureAmplify,
  LAUNCH_DARKLY_KEY,
  USER_SRP_AUTH,
} from '@pumpkincare/portal-config';
import {
  AUTH_VET_LS_KEY,
  getIsLoggedIn,
  initializeUserAgent,
  setIsLoggedIn,
} from '@pumpkincare/portal-shared';
import { GlobalStylesProvider } from '@pumpkincare/portal-ui';
import {
  getVetUserQueryFn,
  getVetUserSelfQueryFn,
  VET_USER_QUERY,
  VET_USER_SELF_QUERY,
} from '@pumpkincare/portal-user';

import { loadLaunchDarkly } from './config/load-launch-darkly';
import { loadSegment } from './config/load-segment';
import { createPumpkinStore, history } from './redux/redux-store';
import routerStoreObservers from './store-observers/router-store-observers';
import App from './view/app';
import AppMeta from './view/app-meta';
import { PUBLIC_PATHS } from './routing';
import { getRouterLocationPathname } from './selectors';

const pumpkinStore = createPumpkinStore();

function AppController() {
  const { dispatch, getState } = pumpkinStore;
  const state = getState();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  const { withLaunchDarkly, ldClient } = loadLaunchDarkly(
    LAUNCH_DARKLY_KEY,
    formatLaunchDarklyUser()
  );

  const EnhancedApp = withLaunchDarkly(App);

  function configureApp() {
    loadSegment(runQueuedAnalytics);
    initializeUserAgent();
  }

  function addListeners() {
    routerStoreObservers(pumpkinStore, queryClient);

    // this won't work if a device switches to a different clinic
    // however that is only only a use case in testing at the moment
    const userObserver = new QueryObserver(queryClient, {
      queryKey: VET_USER_QUERY,
      enabled: false,
    });
    const unsubscribeUser = userObserver.subscribe(({ data, ...rest }) => {
      if (data) {
        ldClient.identify(formatLaunchDarklyUser());

        unsubscribeUser();
      }
    });
  }

  function authenticateUser() {
    const pathname = getRouterLocationPathname(state);

    if (
      getIsLoggedIn(AUTH_VET_LS_KEY) &&
      !PUBLIC_PATHS.some(publicPath => matchPath(pathname, publicPath))
    ) {
      configureAmplify(USER_SRP_AUTH);

      Auth.currentAuthenticatedUser()
        .then(() => {
          setIsLoggedIn(AUTH_VET_LS_KEY, true);

          return queryClient.fetchQuery(
            [VET_USER_SELF_QUERY],
            getVetUserSelfQueryFn
          );
        })
        .then(({ id }) =>
          queryClient.fetchQuery([VET_USER_QUERY], () => getVetUserQueryFn(id))
        )
        .then(user => {
          analyticsIdentify({
            email: user.email,
            firstName: user.first_name,
            lastName: user.last_name,
            accountId: user.vet_practice_id,
          });
        })
        .catch(() => {
          setIsLoggedIn(AUTH_VET_LS_KEY, false);
        });
    }
  }

  function render() {
    const container = document.getElementById('root');
    const root = createRoot(container);

    root.render(
      <GlobalStylesProvider>
        <Provider store={pumpkinStore}>
          <ConnectedRouter history={history}>
            <AppMeta />
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />
              <EnhancedApp />
            </QueryClientProvider>
          </ConnectedRouter>
        </Provider>
      </GlobalStylesProvider>
    );
  }

  authenticateUser();
  configureApp();
  addListeners();
  render();
}

export default AppController;
