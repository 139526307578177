import { Body2 } from '@pumpkincare/portal-ui';

import styles from './learning-lab-aside.css';

import qr from './qr.png';
import blackboard from 'images/illustrations/multipet/blackboard.png';

function LearningLabAside({ clinicCode }) {
  return (
    <div className={styles.root}>
      <img src={blackboard} alt='' className={styles.img} />

      <h3>Start growing your knowledge with Pumpkin Learning Lab</h3>

      <img src={qr} alt='' className={styles.qr} />

      <Body2>
        <b>Use your clinic access code to register:</b>
      </Body2>

      <div className={styles.block}>
        <p>{clinicCode}</p>
      </div>
    </div>
  );
}

export default LearningLabAside;
