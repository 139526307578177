import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './hero-header.css';

import nena from 'images/pet-portraits/nena.png';
import peanut from 'images/pet-portraits/peanut.png';

function HeroHeader({ children, classes = {}, horizontal, text }) {
  return (
    <div className={styles.root}>
      <div
        className={classNames(styles.container, classes.container, {
          [styles.row]: horizontal,
        })}
      >
        <h2 className={classNames(classes.text, styles.text)}>{text}</h2>
        <div className={classNames(classes.images, styles.images)}>
          <img src={nena} className={styles.nena} alt='cat' role='presentation' />
          <img
            src={peanut}
            className={styles.peanut}
            alt='dog'
            role='presentation'
          />
        </div>
        {children}
      </div>
    </div>
  );
}

HeroHeader.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string,
    text: PropTypes.string,
    images: PropTypes.string,
  }),

  horizontal: PropTypes.bool,
  text: PropTypes.string,
};

export default HeroHeader;
