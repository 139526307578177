import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';

import { getTitle } from '../../routing/titles';

import metaImage from 'images/pumpkin-web-share-fs8.png';

const portalUrl = 'https://portal.pumpkin.care';

export default function AppMeta({
  titleData = '',
  description = `Help give your pet the best health care possible with Pumpkin insurance + preventive care.`,
  image = metaImage,
  imageHeight = '',
  imageWidth = '',
  url = portalUrl,
}) {
  const location = useLocation();
  const title = getTitle(location, titleData);

  return (
    <Helmet>
      <title>{title}</title>
      <meta property='og:title' content={title} />
      <meta name='twitter:title' content={title} />
      <meta property='og:description' content={description} />
      <meta name='twitter:description' content={description} />
      <meta name='description' content={description} />
      <meta property='og:image' content={image} />
      <meta property='og:image:height' content={imageHeight} />
      <meta property='og:image:width' content={imageWidth} />
      <meta name='twitter:image' content={image} />
      <meta name='twitter:image:alt' content='Pumpkin® Pet Insurance' />
      <meta property='og:url' content={url} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:site' content='@pumpkinpetcare' />
      <meta property='og:site_name' content='Pumpkin® Pet Insurance' />
      <link rel='canonical' href={location.pathname} />
    </Helmet>
  );
}
