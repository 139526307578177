import PropTypes from 'prop-types';

import { handleShareSocialChannel } from '@pumpkincare/portal-shared';
import { Button } from '@pumpkincare/portal-ui';

import facebookIcon from 'images/logos/facebook.png';
import linkedinIcon from 'images/logos/linkedin.png';
import twitterIcon from 'images/logos/twitter.png';

function SocialShareOptions({ content, vetName }) {
  return (
    <>
      <Button
        onClick={() => handleShareSocialChannel(content, vetName, 'Facebook')}
        aria-label='Share to Facebook'
      >
        <img src={facebookIcon} alt='' /> Facebook
      </Button>
      <Button
        onClick={() => handleShareSocialChannel(content, vetName, 'Twitter')}
        aria-label='Share to Twitter'
      >
        <img src={twitterIcon} alt='' /> Twitter
      </Button>
      <Button
        onClick={() => handleShareSocialChannel(content, vetName, 'LinkedIn')}
        aria-label='Share to LinkedIn'
      >
        <img src={linkedinIcon} alt='' /> LinkedIn
      </Button>
    </>
  );
}

SocialShareOptions.propTypes = {
  content: PropTypes.shape({
    analyticsCategory: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    facebookQuote: PropTypes.string,
    twitterQuote: PropTypes.string,
  }).isRequired,
  vetName: PropTypes.string.isRequired,
};

export default SocialShareOptions;
