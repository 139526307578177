import { trackCustomerPEP } from '@pumpkincare/portal-analytics';
import { isPrevent4 } from '@pumpkincare/portal-shared';
import { A, ButtonPrimary, LegalBody, SubText1 } from '@pumpkincare/portal-ui';

import { PATH_EXT_QUOTE_FLOW } from '../../../app';
import { PP_BASE_UTM_PARAMS } from '../../constants';

import styles from './plans-header.css';

import plusIcon from 'images/icons/plus.svg';

export default function PlansHeader({ vetName, pepVersion }) {
  const displayName = isPrevent4({ version_number: pepVersion })
    ? vetName
    : 'vets like yours';
  return (
    <div className={styles.header}>
      <div className={styles.container}>
        <div className={styles.content}>
          <h2>Help give your pet the best care pawsible with Pumpkin.</h2>

          <div className={styles.topic}>
            <img src={plusIcon} alt='' />
            <LegalBody className={styles.legalBody}>
              Pumpkin Pet Insurance plans to help you get your pet the care they need
              when they're sick or hurt.
            </LegalBody>
          </div>

          <div className={styles.topic}>
            <img src={plusIcon} alt='' />
            <LegalBody className={styles.legalBody}>
              Preventive Essentials packages
              <A href='#disclaimer'>*</A> created with {displayName} to help you get
              refunds for routine wellness care.
            </LegalBody>
          </div>

          <A
            href={`${PATH_EXT_QUOTE_FLOW}?${PP_BASE_UTM_PARAMS}${pepVersion}`}
            onClick={() =>
              trackCustomerPEP('Clicked Create Plan', pepVersion, 'Hero')
            }
            target='_blank'
          >
            <ButtonPrimary className={styles.button}>
              Create Your Pet's Plan
            </ButtonPrimary>
          </A>

          <div id='disclaimer'>
            <SubText1 className={styles.subText}>
              *Preventive Essentials isn't insurance, but an optional wellness
              package you can add to any Pumpkin plan.
            </SubText1>
          </div>
        </div>
      </div>
    </div>
  );
}
