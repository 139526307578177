import { apiPut } from '../../app';
import { setVisitPatient } from '../state';

export default function updateVisitPatient(vetId, patientData, visitId) {
  return (dispatch, getState) => {
    return dispatch(
      apiPut({
        url: `/vets/${vetId}/visits/${visitId}`,
        options: {
          body: {
            client_first_name: patientData.firstName,
            client_last_name: patientData.lastName,
            client_email: patientData.email,
            pet_name: patientData.petName,
          },
          handleActions: setVisitPatient,
        },
      })
    );
  };
}
