import { analyticsTrack } from '@pumpkincare/portal-analytics';
import { QF_URL } from '@pumpkincare/portal-config';
import { CAT, DOG } from '@pumpkincare/portal-shared';
import { ButtonPrimary } from '@pumpkincare/portal-ui';

import formatQuoteFlowRoute from '../../../utils/format-quote-flow-route';
import {
  behavioralAside,
  careTipsTraits,
  formatBreedName,
  rankingMapping,
} from '../constants';

import styles from './care-tips.css';

import checkRibbon from 'images/icons/check-ribbon-red.svg';
import catFace from 'images/illustrations/cat/face.svg';
import dogFace from 'images/illustrations/dog/face.svg';

const images = {
  [CAT]: catFace,
  [DOG]: dogFace,
};

function CareTips({ breedReport, pet, quoteId, hasPrices }) {
  const {
    breed_characteristics: breedCharacteristics,
    care_tips: careTips,
    training_tips: trainingTips,
  } = breedReport;
  const { petBreedSpecies, petName, petBreedName } = pet;

  const aside = behavioralAside[petBreedSpecies];
  const traits = careTipsTraits[petBreedSpecies];

  const fromCopy = trainingTips
    ? 'veterinary and training professionals'
    : 'a veterinarian';

  const header = trainingTips
    ? `Care and training tips for ${formatBreedName(petBreedName)}s`
    : `Care tips fur ${formatBreedName(petBreedName)}s`;

  return (
    <div className={styles.root}>
      <div className={styles.left}>
        <h2>{header}</h2>
        <p>
          Now that you know your {petBreedSpecies} even better, here are some
          breed-specific tips from {fromCopy} to help {petName} live the healthiest
          life possible.
        </p>

        {breedCharacteristics ? (
          <div className={styles.traits}>
            {traits.map(trait =>
              breedCharacteristics[trait.key] ? (
                <div className={styles.trait} key={trait.key}>
                  <img alt='' src={trait.icon} />
                  <p>
                    <b>{trait.title}</b>
                  </p>
                  <p>{rankingMapping[breedCharacteristics[trait.key]]}</p>
                </div>
              ) : null
            )}
          </div>
        ) : null}

        {careTips ? (
          <div className={styles.careTips}>
            <div className={styles.careTipsHeader}>
              <h3>Care Tips</h3>
              <div className={styles.from}>
                <span>{careTips.from}</span>
                <img alt='' src={checkRibbon} />
              </div>
            </div>

            {careTips.tips.map((tip, idx) => (
              <div key={tip.title} className={styles.tip}>
                <h4>{idx + 1}</h4>

                <div>
                  <p className={styles.tipTitle}>
                    <b>{tip.title}</b>
                  </p>
                  <p className={styles.tipDescription}>{tip.description}</p>
                </div>
              </div>
            ))}
          </div>
        ) : null}

        {trainingTips ? (
          <div className={styles.trainingTips}>
            <div className={styles.trainingTipsHeader}>
              <h3>Training Tips</h3>
              <div className={styles.from}>
                <span>{trainingTips.from}</span>
                <img alt='' src={checkRibbon} />
              </div>
            </div>

            {trainingTips.tips.map((tip, idx) => (
              <div key={tip.title} className={styles.tip}>
                <h4>{idx + 1}</h4>

                <div>
                  <p className={styles.tipTitle}>
                    <b>{tip.title}</b>
                  </p>
                  <p className={styles.tipDescription}>{tip.description}</p>
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>

      <div className={styles.right}>
        <div className={styles.aside}>
          <div className={styles.info}>
            <p>
              <b>Did you know?</b>
            </p>
            <ul>
              {aside.map(item => (
                <li key={item}>{item}</li>
              ))}
            </ul>
            <p>
              <b>Get a Pumpkin Pet Insurance Plan for {petName} today.</b>
            </p>

            <a
              href={formatQuoteFlowRoute(quoteId)}
              onClick={() =>
                analyticsTrack({
                  category: 'Pawsights',
                  event: 'CTA Click',
                  label: `Care Tips: Quote - ${hasPrices ? 'True' : 'False'}`,
                })
              }
              target='_blank'
              rel='noreferrer'
            >
              <ButtonPrimary className={styles.button}>
                Fetch a Free Quote
              </ButtonPrimary>
            </a>
          </div>

          <p className={styles.ribbon}>Behavioral problems in {petBreedSpecies}s</p>
          <img alt='' src={images[petBreedSpecies]} />
        </div>
      </div>
    </div>
  );
}

export default CareTips;
