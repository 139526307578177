import { Body1 } from '@pumpkincare/portal-ui';

import { ADDRESS } from '../../../app';

import styles from './dashboard-legal.css';

function DashboardLegal() {
  return (
    <div className={styles.root}>
      <Body1 className={styles.title}>Regulatory reminders and legal notes</Body1>

      <ul>
        <li>
          Do not sell, solicit, or negotiate insurance without a proper insurance
          license. Hospital staff cannot solicit insurance quotes, applications,
          enrollments, or sales. Points are not rewarded for any insurance
          transactions.
        </li>

        <li>
          Do not discuss insurance coverage, rates, promotions, discounts, or risk
          details. Hospital staff should direct clients to contact Pumpkin for any
          insurance-related questions or to discuss insurance coverage.
        </li>

        <li>
          Do not create your own insurance advertising materials. Only content
          provided by Pumpkin is eligible for this challenge. All content is in
          Pumpkin’s voice, please do not represent it as content created by you or
          your staff.
        </li>
      </ul>

      <p>
        Pumpkin Pet Insurance policies do not cover pre-existing conditions. Waiting
        periods, annual deductible, co-insurance, benefit limits and exclusions may
        apply. For full terms, visit pumpkin.care/insurancepolicy. Products,
        discounts, and rates may vary and are subject to change. Insurance products
        are underwritten by either Independence American Insurance Company, a
        Delaware insurance company (NAIC #2658; Scottsdale, AZ), or United States
        Fire Insurance Company (NAIC #21113; Morristown, NJ); and are administered
        and produced by Pumpkin Insurance Services Inc. (“Pumpkin”) (NPN #19084749;
        New York, NY; CA License #6001617). Please refer to your policy forms to
        determine your policy’s underwriter. Pumpkin is a licensed insurance agency,
        not an insurer, and receives compensation based on the premiums for the
        insurance policies it sells.
      </p>

      <p>
        Copyright {new Date().getFullYear()} Pumpkin Insurance Services Inc.  All
        rights reserved. Our mailing address is: {ADDRESS}. Form #100594
      </p>
    </div>
  );
}

export default DashboardLegal;
