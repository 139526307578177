import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { push } from 'connected-react-router';

import { useNavContext } from '@pumpkincare/portal-app';
import {
  formatSearchFromQuery,
  LOADING,
  useTargetState,
} from '@pumpkincare/portal-shared';
import {
  Body1,
  InfiniteScroll,
  Pagination,
  Processing,
  SearchField,
} from '@pumpkincare/portal-ui';

import { getRouterLocation } from '../../../app';
import useLoadOptions from '../../hooks/use-load-patient-options';
import {
  getAllPatientsList,
  getAllPatientsStatus,
  getAllPatientsTotal,
  getPatientsSearchResults,
  getPatientsSearchStatus,
  getPatientsSearchTotal,
} from '../../selectors';
import loadPatientSearch from '../../thunks/load-patient-search';
import loadPatients from '../../thunks/load-patients';
import PatientCard from '../patient-card';
import PatientFilter from '../patient-filter';
import PatientSort from '../patient-sort';

import styles from './patients-landing.css';

import box from 'images/illustrations/multipet/box.png'; // eslint-disable-line import/no-unresolved

export default function PatientsLanding() {
  const dispatch = useDispatch();
  const allPatients = useSelector(getAllPatientsList);
  const allPatientsStatus = useSelector(getAllPatientsStatus);
  const totalPatients = useSelector(getAllPatientsTotal);
  const searchResults = useSelector(getPatientsSearchResults);
  const searchStatus = useSelector(getPatientsSearchStatus);
  const totalSearchPatients = useSelector(getPatientsSearchTotal);
  const { pathname, query } = useSelector(getRouterLocation);
  const { isNavBarOpen } = useNavContext();

  const [keyword, setKeyword] = useTargetState(query.keyword || '');
  const [searchPage, setSearchPage] = useState(1);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [selectedSortBy, setSelectedSortBy] = useState('');
  const isAllPatientsLoading = allPatientsStatus === LOADING;
  const isSearchLoading = searchStatus === LOADING;
  const isSearchDone = totalSearchPatients <= searchPage * 10;
  const isProcessing =
    (!keyword && isAllPatientsLoading) ||
    (keyword && !searchResults && isSearchLoading);
  const {
    loadPatientOptions,
    filterOptions,
    sortByOptions,
    defaultFilter,
    defaultSortBy,
  } = useLoadOptions();
  const filterStatuses = {
    activeOnly: selectedFilter === 'active',
    inactiveOnly: selectedFilter === 'inactive',
  };

  useEffect(() => {
    loadPatientOptions();
  }, [loadPatientOptions]);

  useEffect(() => {
    if (!selectedFilter && defaultFilter) {
      setSelectedFilter(defaultFilter);
    }
  }, [defaultFilter, selectedFilter, setSelectedFilter]);

  useEffect(() => {
    if (!selectedSortBy && defaultSortBy) {
      setSelectedSortBy(defaultSortBy);
    }
  }, [defaultSortBy, selectedSortBy, setSelectedSortBy]);

  function handleSearch(e) {
    const { value } = e.target;
    const newQuery = { ...query };

    if (value) {
      const encodedValue = encodeURIComponent(value);
      dispatch(loadPatientSearch(encodedValue));
      newQuery.keyword = encodedValue;
    } else {
      delete newQuery.keyword;
    }

    setSearchPage(1);
    dispatch(
      push({
        pathname,
        search: formatSearchFromQuery(newQuery),
      })
    );
  }

  function handleSearchScroll() {
    dispatch(loadPatientSearch(query.keyword, searchPage));
    setSearchPage(searchPage + 1);
  }

  function handlePageChange(page) {
    dispatch(
      push({
        pathname,
        search: formatSearchFromQuery({ ...query, page }),
      })
    );

    dispatch(
      loadPatients({
        page: page - 1,
        sortBy: selectedSortBy,
        filter: selectedFilter,
      })
    );
  }

  function onFilterChange(value) {
    dispatch(loadPatients({ filter: value, sortBy: selectedSortBy }));
    setSelectedFilter(value);
    dispatch(
      push({
        pathname,
        search: formatSearchFromQuery({ ...query, page: 1 }),
      })
    );
  }

  function onSortByChange(value) {
    dispatch(loadPatients({ filter: selectedFilter, sortBy: value }));
    setSelectedSortBy(value);
    dispatch(
      push({
        pathname,
        search: formatSearchFromQuery({ ...query, page: 1 }),
      })
    );
  }

  return (
    <div className={styles.container}>
      <h3>My Patients</h3>

      <div className={styles.stickyFilterContainer}>
        <SearchField
          id='search-patient'
          value={keyword}
          onChange={setKeyword}
          onSearch={handleSearch}
          isLoading={isSearchLoading}
          label='Search by Client Name, Email, Phone'
          classes={{
            fieldContainer: classNames(styles.textFieldContainer),
            text: classNames(styles.text),
            container: classNames(styles.searchContainer, {
              [styles.nav]: isNavBarOpen,
            }),
          }}
        />

        {allPatients && !keyword && filterOptions ? (
          <PatientFilter
            options={filterOptions}
            selected={selectedFilter}
            onChangeHandler={onFilterChange}
          />
        ) : null}
      </div>

      <div className={styles.resultsContainer}>
        <div className={styles.sortContainer}>
          {allPatients && !keyword && sortByOptions ? (
            <PatientSort
              options={sortByOptions}
              selected={selectedSortBy}
              onChangeHandler={onSortByChange}
            />
          ) : null}
        </div>

        {!keyword && !isAllPatientsLoading && !allPatients?.length ? (
          <div className={styles.noResults}>
            <img
              role='presentation'
              src={box}
              alt='cat and dog with pumpkin box'
              className={styles.image}
            />
            <Body1>
              It looks like you don't have any patients insured with Pumpkin or sent
              any photo cards yet.
            </Body1>
          </div>
        ) : null}

        {keyword && searchResults ? (
          <InfiniteScroll
            onScrollToBottom={handleSearchScroll}
            isLoading={isSearchLoading}
            isDone={isSearchDone}
          >
            {searchResults.map(result => (
              <PatientCard key={result.email} patient={result} />
            ))}
          </InfiniteScroll>
        ) : null}

        {keyword && !isSearchLoading && !searchResults?.length ? (
          <div className={styles.noResults}>
            <Body1>
              We couldn't find the client you were looking for. Check you entered
              their information correctly and try again.
            </Body1>
          </div>
        ) : null}

        {allPatients && !keyword && !isProcessing
          ? allPatients.map(patient => (
              <PatientCard
                key={patient.email}
                patient={patient}
                filterStatuses={filterStatuses}
              />
            ))
          : null}

        {!keyword && allPatients && totalPatients && !isProcessing ? (
          <Pagination
            totalResults={totalPatients}
            unit='Client'
            page={Number(query.page) || 1}
            onPageChange={handlePageChange}
          />
        ) : null}

        {isProcessing ? <Processing /> : null}
      </div>
    </div>
  );
}
