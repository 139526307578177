import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { push } from 'connected-react-router';

import { useFrames } from '@pumpkincare/portal-app';
import { useToggleState } from '@pumpkincare/portal-shared';
import {
  Body1,
  Button,
  ButtonPrimary,
  ButtonSecondary,
  Modal,
} from '@pumpkincare/portal-ui';
import { getVetSelfVetId, useVetSelf } from '@pumpkincare/portal-user';

import { AppMeta, getRouterLocationQuery, QUERY_VIEWS } from '../../../app';
import FrameForm from '../frame-form';
import PatientForm from '../patient-form';
import PreviewSubmit from '../preview-submit';
import VisitSuccess from '../visit-success';

import styles from './photo-submission.css';

import arrow from '../../../../../../../public/images/icons/arrow-right.svg';
import close from '../../../../../../../public/images/icons/close-inverted.svg';

const [FRAME_SELECTION, PATIENT_FORM, PREVIEW_SUBMIT, SUBMIT_SUCCESS] = QUERY_VIEWS;

/*
  NOTE:
  bc of user's ability to navigate with browser, best to rely on single
  source of truth (url query param) to determine state of component
*/
function PhotoSubmission({
  onClosePopover,
  onImageChange,
  image,
  imageSrc,
  returnCopy,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useSelector(getRouterLocationQuery);
  const { data: vetId } = useVetSelf({ select: getVetSelfVetId });

  const { data: framesData } = useFrames(vetId);

  const [selectedFrameKey, setSelectedFrameKey] = useState(framesData[0].frame_type);
  const [isCloseModalOpen, toggleCloseModal] = useToggleState(false);

  const patientData = useRef({});

  useEffect(() => {
    if (!query.view) {
      onClosePopover();
    }
  }, [query, onClosePopover]);

  function goToNextStep() {
    // eslint-disable-next-line no-use-before-define
    const { next } = steps[query.view];
    dispatch(
      push({ pathname: document.location.pathname, search: `?view=${next}` })
    );
    window.scrollTo(0, 0);
  }

  function handleCloseModal() {
    if (query.view === SUBMIT_SUCCESS) {
      onClosePopover();
    } else {
      toggleCloseModal();
    }
  }

  const steps = {
    [FRAME_SELECTION]: {
      next: PATIENT_FORM,
      component: (
        <FrameForm
          frameKey={selectedFrameKey}
          imageSrc={imageSrc}
          handleFrameOptionClick={setSelectedFrameKey}
          onImageChange={onImageChange}
          goToNextStep={goToNextStep}
        />
      ),
    },
    [PATIENT_FORM]: {
      next: PREVIEW_SUBMIT,
      component: (
        <PatientForm patientData={patientData} goToNextStep={goToNextStep} />
      ),
    },
    [PREVIEW_SUBMIT]: {
      next: SUBMIT_SUCCESS,
      component: (
        <PreviewSubmit
          patientData={patientData.current}
          image={image}
          goToNextStep={goToNextStep}
          frameKey={selectedFrameKey}
        />
      ),
    },
    [SUBMIT_SUCCESS]: {
      component: (
        <VisitSuccess
          onClick={onClosePopover}
          patientData={patientData.current}
          returnCopy={returnCopy}
        />
      ),
    },
  };

  const isBackVisible =
    query.view !== FRAME_SELECTION && query.view !== SUBMIT_SUCCESS;

  return (
    <Modal onClose={handleCloseModal} classes={{ container: styles.container }}>
      {/* To trigger a title change */}
      <AppMeta />
      {isBackVisible ? (
        <Button className={styles.backButton} onClick={history.goBack}>
          <img
            className={styles.chevron}
            src={arrow}
            alt='chevron'
            role='presentation'
          />
          Back
        </Button>
      ) : null}
      {steps[query.view]?.component || null}
      {isCloseModalOpen ? (
        <Modal disabled hasCloseIcon={false} classes={{ container: styles.modal }}>
          <h4>Are you sure you want to leave this page?</h4>
          <Body1 style={{ marginTop: '20px' }}>
            You will lose your uploaded photo.
          </Body1>
          <div className={styles.modalButtons} style={{ marginTop: '20px' }}>
            <ButtonSecondary className={styles.modalButton} onClick={onClosePopover}>
              Leave
            </ButtonSecondary>
            <ButtonPrimary className={styles.modalButton} onClick={toggleCloseModal}>
              Keep Editing
            </ButtonPrimary>
          </div>
        </Modal>
      ) : null}
    </Modal>
  );
}

export default PhotoSubmission;
