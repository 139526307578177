import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AUTH_VET_LS_KEY, getIsLoggedIn } from '@pumpkincare/portal-shared';

import { ClaimSuccess, FileClaim } from '../../claims';
import { CustomerPlans } from '../../customer';
import { Dashboard } from '../../dashboard';
import { PatientsWrapper } from '../../patients';
import { PawsightsFlow } from '../../pawsights';
import { RecordsRequest } from '../../records';
import { CustomerVisitWrapper, PhotoCardGallery } from '../../visit';
import AccountWrapper from '../view/account-wrapper';
import LoginPage from '../view/login-page';
import PageComponent from '../view/page-component';
import PreventiveEssentials from '../view/preventive-essentials';
import PrivatePageComponent from '../view/private-page-component';
import RegisterPage from '../view/register-page';
import RequestPasswordPage from '../view/request-password-page';
import ResetPasswordPage from '../view/reset-password-page';
import UnsubscribePage from '../view/unsubscribe-page';
import {
  PATH_ACCOUNT_BASE,
  PATH_CUSTOMER_BASE,
  PATH_CUSTOMER_PLANS,
  PATH_DASHBOARD,
  PATH_FILE_CLAIM_BASE,
  PATH_FILE_CLAIM_SUCCESS,
  PATH_HOME,
  PATH_LOGIN,
  PATH_PATIENTS_BASE,
  PATH_PAWSIGHTS_BASE,
  PATH_PHOTO_CARD_GALLERY,
  PATH_PREVENTIVE_ESSENTIALS,
  PATH_RECORDS_REQUEST,
  PATH_REGISTER,
  PATH_REQUEST_RESET,
  PATH_RESET_PASSWORD,
  PATH_UNSUBSCRIBE,
} from './paths';

function MainSwitch() {
  const isLoggedIn = getIsLoggedIn(AUTH_VET_LS_KEY);

  return (
    <Switch>
      <Route
        path={PATH_ACCOUNT_BASE}
        render={() => <PrivatePageComponent component={AccountWrapper} />}
      />

      <Route
        path={PATH_CUSTOMER_BASE}
        render={() => (
          <PageComponent hideFooter component={CustomerVisitWrapper} isCustomer />
        )}
      />

      <Route
        path={PATH_CUSTOMER_PLANS}
        render={() => <PageComponent component={CustomerPlans} isCustomer />}
      />

      <Route
        path={PATH_PATIENTS_BASE}
        render={() => <PrivatePageComponent component={PatientsWrapper} />}
      />

      <Route
        path={PATH_FILE_CLAIM_BASE}
        render={() => (
          <PrivatePageComponent component={FileClaim} key={Date.now()} />
        )}
      />

      <Route
        path={PATH_PREVENTIVE_ESSENTIALS}
        render={() => <PrivatePageComponent component={PreventiveEssentials} />}
      />

      <Route
        path={PATH_FILE_CLAIM_SUCCESS}
        render={() => <PrivatePageComponent component={ClaimSuccess} />}
      />

      <Route
        path={PATH_PHOTO_CARD_GALLERY}
        render={() => <PrivatePageComponent component={PhotoCardGallery} />}
      />

      <Route
        path={PATH_REGISTER}
        render={() => <PageComponent isBasicPawPortalNav component={RegisterPage} />}
      />

      <Route
        path={PATH_REQUEST_RESET}
        render={() => (
          <PageComponent isBasicPawPortalNav component={RequestPasswordPage} />
        )}
      />

      <Route
        path={PATH_RESET_PASSWORD}
        render={() => (
          <PageComponent isBasicPawPortalNav component={ResetPasswordPage} />
        )}
      />

      <Route
        path={PATH_LOGIN}
        render={() => <PageComponent isBasicPawPortalNav component={LoginPage} />}
      />

      <Route
        path={PATH_UNSUBSCRIBE}
        render={() => (
          <PageComponent hideNavBar hideFooter component={UnsubscribePage} />
        )}
      />

      <Route path={PATH_PAWSIGHTS_BASE}>
        <PageComponent hideFooter hideNavBar component={PawsightsFlow} />
      </Route>

      <Route path='/records-request'>
        <PageComponent
          hideFooter
          basicHeader='pumpkin'
          component={() => (
            <Switch>
              <Route path={PATH_RECORDS_REQUEST} exact component={RecordsRequest} />
            </Switch>
          )}
        />
      </Route>

      <Route
        path={PATH_HOME}
        exact
        render={() =>
          isLoggedIn ? (
            <Redirect to={PATH_ACCOUNT_BASE} />
          ) : (
            <Redirect to={PATH_LOGIN} />
          )
        }
      />

      <Route path={PATH_DASHBOARD}>
        <PageComponent hideFooter hideNavBar component={Dashboard} />
      </Route>

      <Redirect to={PATH_HOME} />
    </Switch>
  );
}

export default MainSwitch;
