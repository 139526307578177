import { PATH_EXT_UNDERWRITING } from '../../../app';

import styles from './pawsights-nav-bar.css';

import logo from 'images/logos/pumpkin.svg';

function PawsightsNavBar({ vetName, isReport = true, children }) {
  return (
    <nav className={styles.root}>
      <div className={styles.left}>
        <img src={logo} alt='pumpkin' className={styles.logo} />

        {isReport ? (
          <div className={styles.text}>
            <p className={styles.pawsights}>
              Pawsights<sup>®</sup>
              {` Report ${vetName ? `for ${vetName}` : ''}`}
            </p>
            <pre className={styles.separator}>{'  |  '}</pre>
            <a
              href={PATH_EXT_UNDERWRITING}
              className={styles.underwriting}
              target='_blank'
              rel='noreferrer'
            >
              Underwriting
            </a>
          </div>
        ) : null}
      </div>

      <div className={styles.right}>{children}</div>
    </nav>
  );
}

export default PawsightsNavBar;
