import { Link } from 'react-router-dom';

import { GUIDED_PEP_PAGES } from '@pumpkincare/portal-app';
import { BASE_PORTAL_URL } from '@pumpkincare/portal-config';
import { isPrevent4 } from '@pumpkincare/portal-shared';

import { PATH_PREVENTIVE_ESSENTIALS } from '../routing';

import preventiveEssentialsBanner from 'images/illustrations/multipet/preventive-essentials-banner.png';
import dentalHealthBanner from 'images/treats/dental-health.png';

export const DENTAL_HEALTH = 'DENTAL_HEALTH';
export const PREVENTIVE_ESSENTIALS = 'PREVENTIVE_ESSENTIALS';
export const PEP = 'PEP';

export const DENTAL_HEALTH_CARD = {
  template: DENTAL_HEALTH,
  analyticsCategory: 'Send Treats',
  url: 'https://hello.pumpkin.care/blog-dental-health-guide/',
  urlVariable: 'pp_guide_url',
  banner: dentalHealthBanner,
  name: 'Pet Dental Health 101',
  header:
    'Encourage clients to book an annual dental cleaning with your hospital by sharing our dental health guide. Send useful tips on dental disease prevention, at-home dental care, and the importance of regular dental cleanings.',
  emailName: 'Pet Dental Health 101',
  emailBody: [
    'You may not think it, but pet dental disease is common and affects <a target="_blank" style="text-decoration: none; color: #4a6bff;" href="https://vcahospitals.com/know-your-pet/dental-disease-in-dogs">80%</a> of dogs over age three and <a target="_blank" style="text-decoration: none; color: #4a6bff;" href="https://www.vet.cornell.edu/departments-centers-and-institutes/cornell-feline-health-center/health-information/feline-health-topics/feline-dental-disease">50%-90%</a> of cats over age four. The great news is that with regular cleaning and a balanced diet, you can help keep your pet’s teeth healthy and free of dental disease!',
    'With National Pet Dental Health Month coming up in February, we’re partnering with Pumpkin to spread awareness about pet dental disease and to help set you up for dental care success!',
    'Check out Pumpkin’s complete guide, <a target="_blank" style="text-decoration: none; color: #4a6bff;" href="https://hello.pumpkin.care/blog-dental-health-guide/">Pet Dental Health 101</a>, for helpful tips on at-home dental care and how to spot the common signs of dental disease.',
    'And don’t forget to book your pet’s annual dental cleaning with us today!',
  ],
  facebookQuote:
    'Not sure about the best ways to keep your pet’s teeth clean? We’re here to help! We’re partnering with our friends at Pumpkin @pumpkincares to share their complete guide – ‘Pet Dental Health 101’. Read for useful tips to help keep your pet’s teeth healthy and free of dental disease. And don’t forget to book your annual dental cleaning with us today! Check out the guide below ',
  twitterQuote:
    'We’re sharing Pumpkin’s guide Pet Dental Health 101 to give you useful pet dental care tips. Check it out! @pumpkincares ',
};

export const PREVENTIVE_ESSENTIALS_CARD = {
  template: PREVENTIVE_ESSENTIALS,
  url: `${BASE_PORTAL_URL}/customer/plans`,
  urlVariable: 'pp_pep_url',
  redirectUrl: 'preventive-essentials',
  banner: preventiveEssentialsBanner,
  name: 'Pumpkin Preventive Essentials',
  guideKey: GUIDED_PEP_PAGES,
  guideContent: (
    <>
      We just launched a new feature that allows your clinic to view and share
      Preventive Essentials packages available to your clients.
      <Link
        style={{ color: 'inherit', display: 'block', marginTop: '6px' }}
        to={PATH_PREVENTIVE_ESSENTIALS}
      >
        View Packages
      </Link>
    </>
  ),
  header:
    "View and email your clients info about the Preventive Essentials packages available to them. Preventive Essentials isn't insurance, but an optional wellness package they can add to their insurance plan to get refunds for their crucial routine care.",
  emailName: 'Preventive Guide',
  emailBody: [
    "At OUR CLINIC, we want to help you give your pet the best veterinary care possible, so we'd love to tell you how pet insurance can help. When your pet gets sick or hurt, pet insurance can help you afford our best diagnostics & treatment by getting you cash back on covered vet bills.",
  ],
  emailBodyByPlans: plans => {
    if (!plans.length) return '';

    const createdWith = isPrevent4(plans[0])
      ? 'Created with our hospital'
      : 'Created with veterinarians like us';
    const cheapestSavings = plans
      .filter(({ savings }) => !!savings)
      .sort((a, b) => a.savings - b.savings)[0]?.savings;
    const savingsMessage = cheapestSavings
      ? ` Plus, clients of our hospital may save over $${cheapestSavings} on select packages!*`
      : '';

    return `One pet insurance provider, Pumpkin, offers insurance plans and a wellness package called Preventive Essentials. ${createdWith}, Preventive Essentials gives you refunds for crucial wellness services we recommend. While not insurance, Preventive Essentials is an optional benefit you can add to any Pumpkin plan.${savingsMessage} Click the button below for more info.`;
  },
};
