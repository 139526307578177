import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { formatCentsCurrency, formatISODate } from '@pumpkincare/portal-shared';
import { Accordion, Body1, Button } from '@pumpkincare/portal-ui';
import { getVetSelfVetId, useVetSelf } from '@pumpkincare/portal-user';

import { COMPLETED, IN_REVIEW } from '../../constants/claim-status';
import { PREVENT_CLAIM } from '../../constants/claim-types';
import downloadClaim from '../../thunks/download-claim';

import styles from './patient-claim-card.css';

import fileTextIcon from 'images/icons/file-text-blue.svg';

function protectClaimDetail(isAccident, isIllness) {
  if (isAccident && isIllness) {
    return 'Insurance (Accident/Illness)';
  }
  if (isAccident) {
    return 'Insurance (Accident)';
  }
  return 'Insurance (Illness)';
}

function submittedByDetail(submittedBy, vetId) {
  if (!submittedBy) {
    return 'Client';
  }
  if (submittedBy.id === vetId) {
    return 'Your Clinic';
  }

  return 'Other Clinic';
}

export default function PatientClaimCard({ claim }) {
  const dispatch = useDispatch();
  const {
    id,
    status,
    diagnosis_story: diagnosisStory = '',
    amount_claimed: amountClaimed,
    amount_paid: amountPaid,
    claim_number: claimNumber,
    claim_type: claimType,
    date_submitted: dateSubmitted,
    external_claim_id: externalClaimID,
    is_accident: isAccident,
    is_illness: isIllness,
    submitted_by: submittedBy,
    paid_to: paidTo,
  } = claim;

  const isCompleted = status === COMPLETED;

  const claimDetail =
    claimType === PREVENT_CLAIM
      ? 'Preventive Essentials'
      : protectClaimDetail(isAccident, isIllness);

  const downloadClaimLabel =
    claimType === PREVENT_CLAIM
      ? 'Preventive Essentials Claim Summary'
      : 'Insurance Explanation of Benefits';

  const { data: vetId } = useVetSelf({ select: getVetSelfVetId });

  return (
    <div className={styles.container}>
      <Body1 className={styles.claimItem}>
        <span className={styles.claimHeader}>
          Claim ID: {claimNumber}
          {externalClaimID ? ` - ${externalClaimID}` : null}
        </span>
        <span
          className={classNames(styles.claimValue, {
            [styles.claimCompleted]: isCompleted,
            [styles.claimInReview]: status === IN_REVIEW,
          })}
        >
          {status}
        </span>
      </Body1>
      {isCompleted ? (
        <Button
          className={styles.downloadButton}
          onClick={() => {
            dispatch(downloadClaim(claimType, id));
          }}
        >
          <img
            src={fileTextIcon}
            className={styles.fileIcon}
            alt='file icon'
            role='presentation'
          />
          {downloadClaimLabel}
        </Button>
      ) : null}
      <Body1 className={styles.claimItem}>
        <span className={styles.claimProp}>Date Submitted:</span>
        <span className={styles.claimValue}>{formatISODate(dateSubmitted)}</span>
      </Body1>
      <Body1 className={styles.claimItem}>
        <span className={styles.claimProp}>Submitted By:</span>
        <span className={styles.claimValue}>
          {submittedByDetail(submittedBy, vetId)}
        </span>
      </Body1>
      <Body1 className={styles.claimItem}>
        <span className={styles.claimProp}>Claim Type:</span>
        <span className={styles.claimValue}>{claimDetail}</span>
      </Body1>
      <Body1 className={styles.claimItem}>
        <span className={styles.claimProp}>Amount Claimed:</span>
        <span className={styles.claimValue}>
          {amountClaimed ? formatCentsCurrency(amountClaimed) : '-'}
        </span>
      </Body1>
      <Body1 className={styles.claimItem}>
        <span className={styles.claimProp}>Amount Paid:</span>
        <span className={styles.claimValue}>
          {amountPaid ? formatCentsCurrency(amountPaid) : '-'}
        </span>
      </Body1>
      <Body1 className={styles.claimItem}>
        <span className={styles.claimProp}>Payment To:</span>
        <span className={styles.claimValue}>{paidTo}</span>
      </Body1>
      {diagnosisStory ? (
        <Body1 className={styles.claimNotes}>
          {diagnosisStory.length > 90 ? (
            <Accordion
              staticContent={`Visit Note: "${diagnosisStory.substring(0, 85)}..."`}
              closedLabel='Show More'
              isFixedHeader={false}
              openLabel='Show Less'
              classes={{
                container: styles.accordionContainer,
                content: styles.accordionContent,
                row: styles.accordionRow,
              }}
            >
              <div>Visit Note: "{diagnosisStory}"</div>
            </Accordion>
          ) : (
            `Visit Note: "${diagnosisStory}"`
          )}
        </Body1>
      ) : null}
    </div>
  );
}
