import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ERROR, IDLE, LOADING, SUCCESS } from '@pumpkincare/portal-shared';

import { apiPost } from '../../state';

export default function useSendMultiEmail() {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(IDLE);

  const sendMultiEmail = useCallback((e, sendEmails, content) => {
    e.preventDefault();

    setStatus(LOADING);
    dispatch(
      apiPost({
        url: '/utils/send-iterable-emails',
        options: {
          body: {
            emails: sendEmails.map(({ email }) => email),
            template: content.template || undefined,
            [content.urlVariable]: content.url || undefined,
          },
          isAuth: true,
        },
      })
    )
      .then(() => setStatus(SUCCESS))
      .catch(() => setStatus(ERROR));
  }, []);

  const resetHook = useCallback(() => {
    setStatus(IDLE);
  }, []);

  return {
    resetHook,
    sendMultiEmail,
    statuses: {
      isError: status === ERROR,
      isLoading: status === LOADING,
      isSuccess: status === SUCCESS,
    },
  };
}
