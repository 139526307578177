import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFrames } from '@pumpkincare/portal-app';
import { LOADING } from '@pumpkincare/portal-shared';
import { A, Body1, ButtonPrimary } from '@pumpkincare/portal-ui';
import { getVetSelfVetId, useVetSelf } from '@pumpkincare/portal-user';
import { getVetDisplayName, useVet } from '@pumpkincare/portal-vets';

import { PATH_PUMPKIN } from '../../../app';
import {
  getVisitPatientError,
  getVisitPatientStatus,
  getVisitPhotoError,
  getVisitPhotoStatus,
} from '../../selectors';
import submitVisit from '../../thunks/submit-visit';

import styles from './preview-submit.css';

import mail from 'images/icons/mail.svg';

export default function PreviewSubmit({
  goToNextStep,
  image,
  patientData,
  frameKey,
}) {
  const dispatch = useDispatch();
  const patientError = useSelector(getVisitPatientError);
  const photoError = useSelector(getVisitPhotoError);
  const patientStatus = useSelector(getVisitPatientStatus);
  const photoStatus = useSelector(getVisitPhotoStatus);

  const { data: vetId } = useVetSelf({ select: getVetSelfVetId });
  const { data: vetData } = useVet(vetId);
  const vetDisplayName = getVetDisplayName(vetData);

  const { data: framesData } = useFrames(vetId);
  const frame = useMemo(
    () => framesData.find(f => f.frame_type === frameKey),
    [framesData, frameKey]
  );

  const { firstName, lastName, petName } = patientData;
  /*
    bc patient and photo are happening in parallel, isLoading should be
    false if one status is LOADING but the other prop has an error
   */
  const hasError = patientError || photoError;
  const isLoading =
    !hasError && [patientStatus, photoStatus].some(status => status === LOADING);

  function handleClickSubmit() {
    dispatch(submitVisit(vetId, patientData, image, frame)).then(() => {
      goToNextStep();
    });
  }

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>Preview & Send</h4>
      <Body1 className={styles.subtitle}>
        Pumpkin will send your photo card along with the message below to {firstName}{' '}
        {lastName}.
      </Body1>

      <div className={styles.preview}>
        <img
          src={mail}
          role='presentation'
          className={styles.icon}
          alt='mail icon'
        />
        <Body1>Dear {petName},</Body1>
        {frame?.preview_texts.map((text, index) => (
          <Body1 key={`${frameKey}-${index}`} style={{ marginTop: '20px' }}>
            {text}
          </Body1>
        ))}
        <Body1>-Your Friends at {vetDisplayName}</Body1>

        <Body1 style={{ marginTop: '20px' }}>
          P.S. We’re excited to tell you about Pumpkin, the company behind this
          pawesome photo frame! Pumpkin is a new pet care company that offers pet
          insurance. Pet insurance pays you back for eligible accident & illness vet
          bills, making it easier to give your pet the best veterinary care pawsible!{' '}
          <A target='_blank' href={PATH_PUMPKIN}>
            Click here to learn more about Pumpkin.
          </A>
        </Body1>
      </div>

      <ButtonPrimary
        isLoading={isLoading}
        className={styles.submit}
        onClick={handleClickSubmit}
      >
        Send Photo Card
      </ButtonPrimary>
      {hasError ? (
        <Body1 className={styles.error}>
          Sorry, something went wrong! Please try again later or reach out to our
          care team.
        </Body1>
      ) : null}
    </div>
  );
}
