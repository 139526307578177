import { USER_AGENTS } from '@pumpkincare/portal-shared';
import { ButtonSecondary } from '@pumpkincare/portal-ui';

import { CONTACT_HOURS, CONTACT_PAWSIGHTS_PHONE } from '../../../app';

import styles from './pawsights-contact.css';

import customerservice from 'images/illustrations/dog/customer-service.jpg';

function PawsightsContact({ vetName = '' }) {
  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.text}>
          <h3>Questioning if insurance is right for you? We can help!</h3>
          <p>
            As a pet parent{vetName ? ` from ${vetName}` : ''}, you get access to a
            team of Pumpkin insurance pros.
          </p>
          <p>
            We're available{' '}
            <b>
              {CONTACT_HOURS} at {CONTACT_PAWSIGHTS_PHONE} (PAWS).
            </b>
          </p>

          {!USER_AGENTS.desktop ? (
            <div className={styles.buttons}>
              <a href={`tel:${CONTACT_PAWSIGHTS_PHONE}`}>
                <ButtonSecondary>Call Us</ButtonSecondary>
              </a>
              <a href={`sms:${CONTACT_PAWSIGHTS_PHONE}`}>
                <ButtonSecondary>Text Us</ButtonSecondary>
              </a>
            </div>
          ) : null}

          <img src={customerservice} alt='' />
        </div>
      </div>
    </div>
  );
}

export default PawsightsContact;
