import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { A, Accordion, Body1, Button, Processing } from '@pumpkincare/portal-ui';

import PetButton from '../pet-button';

import styles from './patient-nav.css';

import arrow from 'images/icons/arrow-right.svg';
import clipboardIcon from 'images/icons/clipboard.svg';
import emailIcon from 'images/icons/mail.svg';
import phoneIcon from 'images/icons/phone.svg';

export default function PatientNav({
  isMobilePatientHidden = false,
  goBackLink = null,
  goBackText,
  accordionTitle,
  fullPets,
  selectedPet,
  onPetClick,
  user,
}) {
  const history = useHistory();

  const {
    first_name: firstName,
    last_name: lastName,
    email,
    pumpkin_id: pumpkinId,
    phone,
    pets,
  } = user || { pets: [] };

  const backButton = goBackLink ? (
    <Link className={styles.backButton} to={goBackLink}>
      <img
        src={arrow}
        alt='chevron'
        role='presentation'
        className={styles.chevron}
      />
      {goBackText}
    </Link>
  ) : (
    <Button className={styles.backButton} onClick={history.goBack}>
      <img
        src={arrow}
        alt='chevron'
        role='presentation'
        className={styles.chevron}
      />
      {goBackText}
    </Button>
  );

  return (
    <>
      <aside
        className={classNames(styles.menu, {
          [styles.desktop]: isMobilePatientHidden,
        })}
      >
        {backButton}
        <h4 className={styles.title}>
          {firstName} {lastName}
        </h4>
        <Body1 className={styles.info}>
          <img
            src={emailIcon}
            alt='email'
            role='presentation'
            className={styles.infoIcon}
          />
          <A href={`mailto:${email}`}>{email}</A>
        </Body1>
        {phone ? (
          <Body1 className={styles.info}>
            <img
              src={phoneIcon}
              alt='phone'
              role='presentation'
              className={styles.infoIcon}
            />
            {phone}
          </Body1>
        ) : null}
        {pumpkinId ? (
          <Body1 className={styles.info}>
            <img
              src={clipboardIcon}
              alt='clipboard'
              role='presentation'
              className={styles.infoIcon}
            />
            {pumpkinId}
          </Body1>
        ) : null}

        <div className={styles.desktop}>
          <h4 className={styles.title}>Pets</h4>
          {pets.length ? (
            pets.map((pet, index) => (
              <PetButton
                key={pet.name}
                pet={fullPets[pet.id || pet.name] || pet}
                isSelected={
                  (!selectedPet && index === 0) ||
                  (selectedPet &&
                    (pet.id === selectedPet || pet.name === selectedPet))
                }
                onClick={onPetClick}
              />
            ))
          ) : (
            <Processing />
          )}
        </div>
      </aside>
      <div className={styles.mobile}>
        <Accordion
          staticContent={<h3>{accordionTitle}</h3>}
          closedLabel='Change Pet'
          openLabel='Cancel'
          classes={{
            container: isMobilePatientHidden
              ? styles.accordionBackground
              : styles.accordionContainer,
          }}
        >
          {pets.map(pet => (
            <PetButton
              key={pet.name}
              pet={fullPets[pet.id || pet.name] || pet}
              onClick={onPetClick}
            />
          ))}
        </Accordion>

        {isMobilePatientHidden ? (
          <div className={classNames(styles.mobile, styles.goBackMobileOnly)}>
            {backButton}
          </div>
        ) : null}
      </div>
    </>
  );
}
