import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { formatISODate } from '@pumpkincare/portal-shared';
import { Body2 } from '@pumpkincare/portal-ui';

import styles from './challenge.css';

import q3Challenge from './q3-challenge-header.png';
import reports from 'images/illustrations/multipet/reports.png';

function Challenge({ challengeData = {} }) {
  const { dawgs1968Q3Dashboard } = useFlags();

  const {
    start_date: startDate,
    end_date: endDate,
    current_value: currentValue,
    target_value: targetValue,
    is_active: isActive,
  } = challengeData;

  return (
    <div className={styles.root}>
      <img
        src={dawgs1968Q3Dashboard ? q3Challenge : reports}
        alt=''
        className={styles.img}
      />

      <h3>
        {dawgs1968Q3Dashboard
          ? 'Number of new pet owner visits to the Pumpkin website'
          : 'Number of visits to Pawsights® so far...'}
      </h3>
      {isActive ? (
        <Body2>
          Challenge Period of {formatISODate(startDate, { format: 'MMM D' })} to{' '}
          {formatISODate(endDate, { format: 'MMM D, YYYY' })}
        </Body2>
      ) : (
        <Body2>Since {formatISODate(startDate, { format: 'MMM D' })}</Body2>
      )}

      <p
        className={classNames(styles.number, {
          [styles.inactive]: !isActive,
        })}
      >
        {isActive && !dawgs1968Q3Dashboard
          ? `${currentValue} / ${targetValue}`
          : currentValue}
      </p>

      {dawgs1968Q3Dashboard ? (
        <div className={styles.block}>
          <p>
            <b>
              Win 1 of 4 prizes based on your team's total visit count, up to 150
              visits!
            </b>
          </p>
          <p>
            Plus, get 150 or more new visits during the challenge and Pumpkin will
            continue to supply your practice with custom brochures moving forward!
          </p>
        </div>
      ) : null}
    </div>
  );
}

export default Challenge;
