import { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { QF_URL } from '@pumpkincare/portal-config';
import {
  PET_AGE_OPTIONS,
  useBreeds,
  useMutateAffiliateQuote,
  useTargetState,
  useZipcodes,
  validateEmail,
  validateZip,
} from '@pumpkincare/portal-shared';
import { ButtonPrimary, TextField, ToggleButton } from '@pumpkincare/portal-ui';

import styles from './widget.css';

import catFace from 'images/icons/cat-face.svg';
import catFemale from 'images/icons/cat-female.svg';
import catMale from 'images/icons/cat-male.svg';
import dogFace from 'images/icons/dog-face.svg';
import dogFemale from 'images/icons/dog-female.svg';
import dogMale from 'images/icons/dog-male.svg';
import coverage from 'images/illustrations/multipet/coverage.png';

/*
 * A shortened quote flow with steps for name / species, breed / age / gender, and user info before redirecting to get.pumpkin.ENV
 * @param {string} defaultPetName - name to default in state
 * */
function Widget({ defaultPetName, title, subtitle, redirectUtms }) {
  const [showErrors, setShowErrors] = useState(false);
  const [viewIndex, setViewIndex] = useState(0);

  const [petName, setPetName] = useTargetState(defaultPetName);
  const [species, setSpecies] = useState('');

  const [petBreed, setBreed] = useTargetState('');
  const [petAge, setAge] = useTargetState('');
  const [petGender, setGender] = useState('');

  const [firstName, setFirstName] = useTargetState('');
  const [lastName, setLastName] = useTargetState('');
  const [zip, setZip] = useTargetState('', validateZip);
  const [email, setEmail, isEmailValid] = useTargetState('', validateEmail);

  // only fetch breeds after moving to breeds view
  const { data: breeds } = useBreeds(species, {
    enabled: !!species && viewIndex >= 1,
  });
  const { isLoading: isAffiliateLoading, mutateAsync: postAffiliateQuote } =
    useMutateAffiliateQuote();
  // this endpoint returns a 404 if invalid input, so just forcing the data return to be consistent
  const { data: zipData = {} } = useZipcodes(zip);
  const isZipValid = !!zipData.zip;

  function handleIntro() {
    if (!petName || !species) {
      setShowErrors(true);
      return;
    }

    setShowErrors(false);
    setViewIndex(state => state + 1);
  }

  function handlePetInfo() {
    if (!petBreed || !petAge || !petGender) {
      setShowErrors(true);
      return;
    }

    setShowErrors(false);
    setViewIndex(state => state + 1);
  }

  function handleUserInfo() {
    if (!firstName || !lastName || !isZipValid || !isEmailValid) {
      setShowErrors(true);
      return;
    }

    setShowErrors(false);

    postAffiliateQuote({
      partner: 'pawportal-photo-card',
      first_name: firstName,
      last_name: lastName,
      email,
      policy_zipcode: zip,
      quote_pets: [
        {
          breed_code: petBreed,
          pet_age: petAge,
          pet_gender: petGender,
          pet_name: petName,
        },
      ],
    })
      .then(quote => {
        setViewIndex(state => state + 1);
        setTimeout(() => {
          // TODO: replace when upgrading to React-Router 6 DAWGS-1697
          window.location.replace(
            `${QF_URL}/plan-selection/${quote.id}?${redirectUtms}`
          );
        }, 1000);
      })
      .catch(err => {
        console.log(err);
      });
  }

  function handleSubmit(e) {
    e.preventDefault();

    switch (viewIndex) {
      case 0:
        return handleIntro();
      case 1:
        return handlePetInfo();
      case 2:
        return handleUserInfo();
      default:
        return () => {};
    }
  }

  const name = viewIndex >= 1 ? petName : defaultPetName;

  return (
    <form className={styles.root} onSubmit={handleSubmit}>
      {viewIndex !== 3 ? (
        <>
          <h4 className={styles.title}>
            {title ??
              `Help ${name} get the best care when ruh-rohs happen with a Pumpkin insurance plan.`}
          </h4>
          <p className={styles.subtitle}>
            {subtitle ??
              `Add ${name}'s info to get a quote for best-in-class accident and illness coverage.`}
          </p>
        </>
      ) : null}

      {viewIndex === 0 ? (
        <>
          <TextField
            id='petName'
            value={petName}
            label="Your Pet's Name"
            onChange={setPetName}
            variant='portal'
            classes={{
              container: styles.fullWidth,
              fieldContainer: styles.marginZero,
            }}
            error={{
              errorMessage: showErrors && !petName ? 'This field is required' : null,
            }}
          />

          <ToggleButton
            label='Dog'
            icon={dogFace}
            selected={species === 'dog'}
            clickHandler={() => setSpecies('dog')}
            classes={{
              container: classNames(styles.toggle, {
                [styles.errorBorder]: showErrors && !species,
              }),
              img: styles.toggleImg,
            }}
            variant='portal'
          />
          <ToggleButton
            label='Cat'
            icon={catFace}
            selected={species === 'cat'}
            clickHandler={() => setSpecies('cat')}
            classes={{
              container: classNames(styles.toggle, {
                [styles.errorBorder]: showErrors && !species,
              }),
              img: styles.toggleImg,
            }}
            variant='portal'
          />
          {showErrors && !species ? (
            <p className={styles.error}>This field is required</p>
          ) : null}

          <ButtonPrimary className={styles.fullWidth} type='submit'>
            Get Started
          </ButtonPrimary>
        </>
      ) : null}

      {viewIndex === 1 ? (
        <>
          <select
            aria-label='Select a breed'
            name='breeds'
            id='breeds'
            onChange={setBreed}
            className={classNames(styles.select, {
              [styles.errorBorder]: showErrors && !petBreed,
              [styles.filled]: !!petBreed,
            })}
          >
            <option value=''>Select a breed</option>
            {breeds.map(breed => (
              <option key={breed.code} value={breed.code}>
                {breed.name}
              </option>
            ))}
          </select>
          {showErrors && !petBreed ? (
            <p className={styles.error}>This field is required</p>
          ) : null}

          <select
            aria-label='Select an age'
            name='ages'
            id='ages'
            onChange={setAge}
            className={classNames(styles.select, {
              [styles.errorBorder]: showErrors && !petAge,
              [styles.filled]: !!petAge,
            })}
          >
            <option value=''>Select an age</option>
            {PET_AGE_OPTIONS.map(age => (
              <option key={age.value} value={age.value}>
                {age.label}
              </option>
            ))}
          </select>
          {showErrors && !petAge ? (
            <p className={styles.error}>This field is required</p>
          ) : null}

          <ToggleButton
            label='Boy'
            selected={petGender === 'male'}
            icon={species === 'dog' ? dogMale : catMale}
            clickHandler={() => setGender('male')}
            classes={{
              container: classNames(styles.toggle, {
                [styles.errorBorder]: showErrors && !petGender,
              }),
              img: styles.toggleImg,
            }}
            variant='portal'
          />
          <ToggleButton
            label='Girl'
            selected={petGender === 'female'}
            icon={species === 'dog' ? dogFemale : catFemale}
            clickHandler={() => setGender('female')}
            classes={{
              container: classNames(styles.toggle, {
                [styles.errorBorder]: showErrors && !petGender,
              }),
              img: styles.toggleImg,
            }}
            variant='portal'
          />
          {showErrors && !petGender ? (
            <p className={styles.error}>This field is required</p>
          ) : null}

          <ButtonPrimary className={styles.fullWidth} type='submit'>
            Continue
          </ButtonPrimary>
        </>
      ) : null}

      {viewIndex === 2 ? (
        <>
          <TextField
            value={firstName}
            label='Enter your first name'
            onChange={setFirstName}
            id='firstName'
            variant='portal'
            classes={{
              container: styles.halfWidth,
              fieldContainer: styles.marginZero,
            }}
            error={{
              errorMessage:
                showErrors && !firstName ? 'This field is required' : null,
            }}
          />
          <TextField
            value={lastName}
            label='Enter your last name'
            onChange={setLastName}
            id='lastName'
            variant='portal'
            classes={{
              container: styles.halfWidth,
              fieldContainer: styles.marginZero,
            }}
            error={{
              errorMessage:
                showErrors && !lastName ? 'This field is required' : null,
            }}
          />
          <TextField
            value={zip}
            label='Enter your home ZIP code'
            onChange={setZip}
            id='zip'
            variant='portal'
            classes={{
              container: styles.fullWidth,
              fieldContainer: styles.marginZero,
            }}
            error={{
              errorMessage:
                showErrors && !isZipValid ? 'Please enter a valid zipcode' : null,
            }}
          />
          <TextField
            value={email}
            label='Enter your email address'
            onChange={setEmail}
            id='email'
            variant='portal'
            classes={{
              container: styles.fullWidth,
              fieldContainer: styles.marginZero,
            }}
            error={{
              errorMessage:
                showErrors && !isEmailValid ? 'Please enter a valid email' : null,
            }}
          />
          <ButtonPrimary
            isLoading={isAffiliateLoading}
            className={styles.fullWidth}
            type='submit'
          >
            Fetch My Free Quote
          </ButtonPrimary>
        </>
      ) : null}

      {viewIndex === 3 ? (
        <>
          <h2 className={styles.create}>Creating your plan</h2>
          <p className={styles.wait}>Please wait...</p>

          <img className={styles.img} src={coverage} alt='' />
        </>
      ) : null}
    </form>
  );
}

Widget.defaultProps = {
  redirectUtms:
    'utm_source=vetmktPet&utm_medium=email&utm_campaign=shortquote&utm_content=photocard',
};

Widget.propTypes = {
  defaultPetName: PropTypes.string.isRequired,
  title: PropTypes.node,
  subtitle: PropTypes.node,
  redirectUtms: PropTypes.string,
};

export default Widget;
